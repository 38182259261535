import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const RegisterSuppliers = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  //const [logo, setLogo] = useState('');
  //const [pacraCertificate, setPacraCertificate] = useState('');

  const [businessName, setBusinessName] = useState('')
  const [supplierName, setSupplierName] = useState('')
  const [address, setAddress] = useState('')
  const [ip, setIP] = useState('')
  const [businessNameDropDown, setBusinessNameDropDown] = useState([])
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [phone, setPhone] = useState('')
  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  //const [success, setSuccess ] = useState(false);

  useEffect(() => {
    // storing input name
    //localStorage.setItem("ruth", JSON.stringify(businessName));
  }, [])

  useEffect(() => {
    businessNameRef.current.focus()
  }, [])

  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
  }, [phone])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    setErrMsg('')
  }, [phone, email])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  //alert("id = "+systemSetupIDInitial);

  useEffect(() => {
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getViewBusinesses')
    formDataViewBusinesses.append('businessNameID', systemSetupIDInitial)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
      .then(response => {
        setBusinessNameDropDown(response.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  /*useEffect( () => {
        Axios.get("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            //console.log(response);
            });
        },
        [])*/

  useEffect(() => {
    var formDataViewBusinessBranchNames = new FormData(this)
    formDataViewBusinessBranchNames.append(
      'apiType',
      'getBusinessBranchNameDropDown'
    )
    formDataViewBusinessBranchNames.append(
      'businessNameID',
      systemSetupIDInitial
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinessBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      /*Axios.post(`${server_app_api}/api/getBusinessBranchNameDropDown`, {
      businessNameID: businessName
    })*/
      .then(response => {
        setBusinessBranchNameDropDown(response.data)
      })
    //.catch(err => alert('ERROR : Kindly Contact Your Administrator'))
  }, [businessName])

  /*setBusinessName = async (e) => 
            {
alert("changed business name value");
            }*/

  const handleSubmit = async e => {
    //alert("businessName = "+businessName);
    
    const currentDate = new Date()
    const dateToday = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    const timeNow =
      currentDate.getHours() +
      ':' +
      currentDate.getMinutes() +
      ':' +
      currentDate.getSeconds()

    const firstUserID = localStorage.getItem('userID')
    const userIDInitial = JSON.parse(firstUserID)
    var userID = userIDInitial

    /*Axios.post(`${server_app_api}/api/insertRegisterSuppliers`, {
      businessName: businessName,
      supplierName: supplierName,
      phone: phone,
      email: email,
      address: address,
      userID: userID,
      system_setup_IP: ip,
      dateToday: dateToday,
      timeNow: timeNow
    })*/

    setHandleSubmitBtn('Loading');
    var formDataRegisterSuppliers = new FormData(this)
    formDataRegisterSuppliers.append('apiType', 'insertRegisterSuppliers')
    //formDataRegisterSuppliers.append('businessNameID', systemSetupIDInitial)
    formDataRegisterSuppliers.append('businessName', businessName)
    formDataRegisterSuppliers.append('supplierName', supplierName)
    formDataRegisterSuppliers.append('phone', phone)
    formDataRegisterSuppliers.append('email', email)
    formDataRegisterSuppliers.append('address', address)
    formDataRegisterSuppliers.append('userID', userID)
    formDataRegisterSuppliers.append('system_setup_IP', ip)
    formDataRegisterSuppliers.append('dateToday', dateToday)
    formDataRegisterSuppliers.append('timeNow', timeNow)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRegisterSuppliers,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        alert(res.data)
        setHandleSubmitBtn('')
      })
      .catch(err =>{ alert('ERROR : Kindly Contact Your Adminitrator')
      setHandleSubmitBtn('') 
    })
  }

  // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); //output= 2020-5-24
  //var time = new Date().toTimeString();

  //const time = `${currentDate.getTime()}`;
  //var today = new Date(),
  //alert("Time Is = "+timeNow+" Today's Date = "+date);
  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Register Stock Suppliers -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div> -->*/}
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row mt-5'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <div class='form-header white-text ocean blue-gradient'>
                    {' '}
                    REGISTER SUPPLIERS HERE{' '}
                  </div>
                  {/*Start Of Row 1*/}
                  <div class='row'>
                    <div class='col-lg-6 col-md-12 mb-1'>
                      <div class='grey-text'>
                        <label>
                          Business Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          id='businessName'
                          onChange={e => setBusinessName(e.target.value)}
                          autoComplete='off'
                          required
                          ref={businessNameRef}
                        >
                          <option value=''>Choose Business Name ..</option>
                          {businessNameDropDown.map(val => {
                            return (
                              <option value={val.system_setupID}>
                                {val.organisation}{' '}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-6 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Supplier Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          id='supplierName'
                          onChange={e => setSupplierName(e.target.value)}
                          autoComplete='off'
                          value={supplierName}
                          required
                        />
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  <div class='row'>
                    <div class='col-lg-6 col-md-12 mb-1'>
                      <div class='md-form'>
                        {' '}
                        <label for='registerFullName'>
                          Supplier Phone <i class='red-text'>*</i>
                          <span className={validPhone ? 'valid' : 'hide'}>
                            {' '}
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{' '}
                          <span
                            className={
                              validPhone || !phone ? 'hide' : 'invalid'
                            }
                          >
                            {' '}
                            <FontAwesomeIcon icon={faTimes} />
                          </span>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          id='phone'
                          onChange={e => setPhone(e.target.value)}
                          autoComplete='off'
                          value={phone}
                          required
                          aria-invalid={validPhone ? 'false' : 'true'}
                          aria-describedby='phonenote'
                          onFocus={() => setPhoneFocus(true)}
                          onBlur={() => setPhoneFocus(false)}
                        />
                        <p
                          id='phonenote'
                          className={
                            phoneFocus && phone && !validPhone
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          there should be 10 or more, but less than 14 numerical
                          values. <br />
                          You can also add + followed by your country or area
                          phone number code.
                        </p>
                      </div>
                    </div>

                    <div class='col-lg-6 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Supplier Email <i class='red-text'>*</i>
                          <span className={validEmail ? 'valid' : 'hide'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={
                              validEmail || !email ? 'hide' : 'invalid'
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        </label>
                        <br />

                        <input
                          type='text'
                          class='form-control'
                          id='email'
                          onChange={e => setEmail(e.target.value)}
                          autoComplete='off'
                          value={email}
                          required
                          aria-invalid={validEmail ? 'false' : 'true'}
                          aria-describedby='emailnote'
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                        />
                        <p
                          id='emailnote'
                          className={
                            emailFocus && email && !validEmail
                              ? 'instructions'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          4 to 24 characters. <br />
                          Let It Be A Valid Email Address.
                        </p>
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 3*/}
                  {/*Start Of Row 1*/}
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Supplier Address <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <textarea
                          class='form-control'
                          id='address'
                          onChange={e => setAddress(e.target.value)}
                          autoComplete='off'
                          value={address}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  <p>
                    <span class='red-text'>* Required</span>{' '}
                    <span>- Do not leave Blank</span>
                  </p>
                  <hr class='mb-1' />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-primary'
                    disabled={
                      businessName === '' ||
                      supplierName === '' ||
                      address === '' ||
                      !validPhone ||
                      !validEmail ||
                      handleSubmitBtn === 'Loading'
                    }
                  >
              <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                    Submit
                  </button>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default RegisterSuppliers
