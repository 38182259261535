import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const QUANTITY_REGEX = /^\d{1,9}$/
//const PRICE_REGEX = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/ ;
const PRICE_REGEX = /^\d+(,\d{3})*(\.\d{2,2})?$/
//const PRICE_REGEX = /^(?!0+$)*[,\d{3}]*{0,10}$/;
//const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/;

const RegisterStock = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const barcodeRef = useRef()
  const errRef = useRef()

  const [dataInputMethod, setDataInputMethod] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [productName, setProductName] = useState('')
  const [scanner, setScanner] = useState('')
  const [supplier, setSupplier] = useState('')
  const [arrivalDate, setArrivalDate] = useState('')
  const [reOrderLevelType, setReOrderLevelType] = useState('')
  const [currency, setCurrency] = useState('')
  const [ip, setIP] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  /*const firstUserID = localStorage.getItem("userID");
const userIDInitial = JSON.parse(firstUserID);*/

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  //const [userID,setUserID] = useState(userIDInitial);
  const [businessNameDropDown, setBusinessNameDropDown] = useState([])
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [productNameDropDown, setProductNameDropDown] = useState([])
  const [supplierDropDown, setSupplierDropDown] = useState([])

  var [currentExchangeRate, setCurrentExchangeRate] = useState('')
  const [validCurrentExchangeRate, setValidCurrentExchangeRate] =
    useState(false)
  const [currentExchangeRateFocus, setCurrentExchangeRateFocus] =
    useState(false)

  var [orderExchangeRate, setOrderExchangeRate] = useState('')
  const [validOrderExchangeRate, setValidOrderExchangeRate] = useState(false)
  const [orderExchangeRateFocus, setOrderExchangeRateFocus] = useState(false)

  var [productUnitCost, setProductUnitCost] = useState('')
  const [validProductUnitCost, setValidProductUnitCost] = useState(false)
  const [productUnitCostFocus, setProductUnitCostFocus] = useState(false)

  const [quantity, setQuantity] = useState('')
  const [validQuantity, setValidQuantity] = useState(false)
  const [quantityFocus, setQuantityFocus] = useState(false)

  var [cost, setCost] = useState('')
  const [validCost, setValidCost] = useState(false)
  const [costFocus, setCostFocus] = useState(false)

  const [reOrderLevel, setReOrderLevel] = useState('')
  const [validReOrderLevel, setValidReOrderLevel] = useState(false)
  const [reOrderLevelFocus, setReOrderLevelFocus] = useState(false)

  const [foreignCurrencyValue, setForeignCurrencyValue] = useState('')
  const [validForeignCurrencyValue, setValidForeignCurrencyValue] =
    useState(false)
  const [foreignCurrencyValueFocus, setForeignCurrencyValueFocus] =
    useState(false)


  const [price, setPrice] = useState('')
  const [validPrice, setValidPrice] = useState(false)
  const [priceFocus, setPriceFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);
  const [excelFile, setExcelFile] = useState('')
  const [excelFileName, setExcelFileName] = useState('')
  const [excelFileType, setExcelFileType] = useState('')

  //var [barcode, setBarcode] = useState('')

  useEffect(() => {
    if (scanner === '' || scanner === 'No' || scanner === undefined) {
      setBarcode('')
      setQuantity('')
      setServiceList([        
        {
          barcode: '',
      stockQuantity: ''
        }
      ])
    } else {
      setBarcode('')
      setQuantity('')
      setServiceList([        
        {
          barcode: '',
      stockQuantity: ''
        }
      ])
    }
    //alert(period)
  }, [scanner, dataInputMethod, businessName, businessBranchName, productName, currency])

  useEffect(() => {
    //barcodeRef.current.focus()
  }, [])

  useEffect(() => {
    const result = QUANTITY_REGEX.test(quantity)
    setValidQuantity(result)
  }, [quantity])

  useEffect(() => {
    const result = QUANTITY_REGEX.test(currentExchangeRate)
    setValidCurrentExchangeRate(result)
  }, [currentExchangeRate])

  useEffect(() => {
    const result = QUANTITY_REGEX.test(orderExchangeRate)
    setValidOrderExchangeRate(result)
  }, [orderExchangeRate])

  useEffect(() => {
    const result = QUANTITY_REGEX.test(productUnitCost)
    setValidProductUnitCost(result)
  }, [productUnitCost])

  useEffect(() => {
    const result = QUANTITY_REGEX.test(reOrderLevel)
    setValidReOrderLevel(result)
  }, [reOrderLevel])

  useEffect(() => {
    const result = PRICE_REGEX.test(cost)
    setValidCost(result)
  }, [cost])

  useEffect(() => {
    const result = PRICE_REGEX.test(foreignCurrencyValue)
    setValidForeignCurrencyValue(result)
  }, [foreignCurrencyValue])

  useEffect(() => {
    const result = PRICE_REGEX.test(price)
    setValidPrice(result)
  }, [price])

  useEffect(() => {
    setErrMsg('')
  }, [quantity, cost, price])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    //const res = await Axios.get('https://www.xe.com/currencyconverter/convert/?Amount=1&From=USD&To=ZMW')
    console.log(res.data)
    setIP(res.data.IPv4)
    //setIP(res.data.Amount)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])
  //alert (ip);
  /*useEffect( () => {
        Axios.get("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            });
        },
        [])*/

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getViewBusinesses')
    formDataViewBusinesses.append('businessNameID', systemSetupIDInitial)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessNameDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessBranchNameDropDown`, {
      businessNameID: businessName
    })*/
    var formDataViewBusinessBranchNames = new FormData(this)
    formDataViewBusinessBranchNames.append(
      'apiType',
      'getBusinessBranchNameDropDown'
    )
    formDataViewBusinessBranchNames.append(
      'businessNameID',
      systemSetupIDInitial
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinessBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [businessName])

  useEffect(() => {
    //alert(businessName);
    /*Axios.post(`${server_app_api}/api/getProductNameDropDown`, {
      businessNameID: businessName
    })*/
    var formDataProductNames = new FormData(this)
    formDataProductNames.append('apiType', 'getProductNameDropDown')
    formDataProductNames.append('businessNameID', businessName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataProductNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setProductNameDropDown(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [businessName])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getSupplierDropDown`, {
      businessNameID: businessName
    })*/
    var formDataProductSupplier = new FormData(this)
    formDataProductSupplier.append('apiType', 'getSupplierDropDown')
    formDataProductSupplier.append('businessNameID', businessName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataProductSupplier,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setSupplierDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [businessName])

  const saveExcelFile = e => {
    setExcelFile(e.target.files[0])
    setExcelFileName(e.target.files[0].name)
    setExcelFileType(e.target.files[0].Type)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setHandleSubmitBtn('Loading');
    var formData = new FormData(this)

    //alert("businessName = "+businessName);
    const currentDate = new Date()
    const dateToday = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    const timeNow =
      currentDate.getHours() +
      ':' +
      currentDate.getMinutes() +
      ':' +
      currentDate.getSeconds()

    const dateTodayBatch = `${currentDate.getDate()}${
      currentDate.getMonth() + 1
    }${currentDate.getFullYear()}`
    const timeNowBatch =
      currentDate.getHours() +
      '' +
      currentDate.getMinutes() +
      '' +
      currentDate.getSeconds()

    const firstUserID = localStorage.getItem('userID')
    const userIDInitial = JSON.parse(firstUserID)
    var userID = userIDInitial
    //alert(dataInputMethod);
    if (dataInputMethod != 'Normal Single Entry') {
      //alert(excelFile.type);

      /*if(excelFile.type ==="xls" && "xlsx" && "csv" && "docx" )
    {*/
      formData.append('excelFile', excelFile)
      formData.append('excelFileName', excelFileName)
      formData.append('businessName', businessName)
      formData.append('businessBranchName', businessBranchName)
      formData.append('userID', userID)
      formData.append('dateToday', dateToday)
      formData.append('timeNow', timeNow)
      formData.append('system_setup_IP', ip)
      formData.append('dateTodayBatch', dateTodayBatch)
      formData.append('timeNowBatch', timeNowBatch)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/registerStockFromExcelSheet.php`,
        //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerStockFromExcelSheet.php',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(function (response) {          
          //handle success
          setHandleSubmitBtn('')
          setBusinessName('')
          setBusinessBranchName('')
          setExcelFile('')
          setExcelFileName('')
          setDataInputMethod('')
          alert(response.data)
        })
        .catch(function (response) {
          //handle error
          //alert('ERROR : Kindly Contact Your Adminitrator')
        })
      /*}
    else
    {
    alert ("You Can Only Upload An Excel File"); 
    }*/
    } else {
      if (currency === 'Kwacha') {
        currentExchangeRate = ''
        orderExchangeRate = ''
        productUnitCost = ''
        cost = cost
      }
      if (currency != 'Kwacha') {
        //alert(currency);
        currentExchangeRate = currentExchangeRate
        orderExchangeRate = orderExchangeRate
        productUnitCost = productUnitCost
        cost = ''

        //alert("currentExchangeRate = "+currentExchangeRate+ "orderExchangeRate = "+orderExchangeRate+ "productUnitCost"+productUnitCost+ "cost = "+cost);
      }
      /*Axios.post(`${server_app_api}/api/insertStock`, {
        businessName: businessName,
        businessBranchName: businessBranchName,
        productName: productName,
        quantity: quantity,
        currentExchangeRate: currentExchangeRate,
        orderExchangeRate: orderExchangeRate,
        productUnitCost: productUnitCost,
        cost: cost,
        price: price,
        supplier: supplier,
        reOrderLevelType: reOrderLevelType,
        reOrderLevel: reOrderLevel,
        currency: currency,
        arrivalDate: arrivalDate,
        userID: userID,
        system_setup_IP: ip,
        dateToday: dateToday,
        timeNow: timeNow,
        dateTodayBatch: dateTodayBatch,
        timeNowBatch: timeNowBatch
      })*/

      setHandleSubmitBtn('Loading');
      var formDataStock = new FormData(this)
      formDataStock.append('apiType', 'insertStock')
      formDataStock.append('businessName', businessName)
      formDataStock.append('businessBranchName', businessBranchName)
      formDataStock.append('productName', productName)
      formDataStock.append('barcode', barcode)
      formDataStock.append('stockQuantity', stockQuantity)
      formDataStock.append('quantity', quantity)
      formDataStock.append('currentExchangeRate', currentExchangeRate)
      formDataStock.append('orderExchangeRate', orderExchangeRate)
      formDataStock.append('productUnitCost', productUnitCost)
      formDataStock.append('cost', cost)
      formDataStock.append('price', price)
      formDataStock.append('supplier', supplier)
      formDataStock.append('reOrderLevelType', reOrderLevelType)
      formDataStock.append('reOrderLevel', reOrderLevel)
      formDataStock.append('currency', currency)
      formDataStock.append('arrivalDate', arrivalDate)
      formDataStock.append('userID', userID)
      formDataStock.append('system_setup_IP', ip)
      formDataStock.append('dateToday', dateToday)
      formDataStock.append('timeNow', timeNow)
      formDataStock.append('dateTodayBatch', dateTodayBatch)
      formDataStock.append('timeNowBatch', timeNowBatch)
      Axios({
        method: 'post',
        url: `${server_app_api_php}/reactphp/index.php`,
        data: formDataStock,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          if(res.data === 'Stock Uploaded Successfully'){
          setHandleSubmitBtn('')
          setBusinessName('')
          setBusinessBranchName('')
          setProductName('')
          setScanner('')
          setBarcode('')
          setQuantity('')
          setCurrentExchangeRate('')
          setOrderExchangeRate('')
          setProductUnitCost('')
          setCost('')
          setPrice('')
          setSupplier('')
          setReOrderLevelType('')
          setReOrderLevel('')
          setCurrency('')
          setArrivalDate('')
          setDataInputMethod('')
          setServiceList([        
            {
              barcode: '',
              stockQuantity: ''
            }
          ])
          alert(res.data)
          }
          else{
            setHandleSubmitBtn('')
            alert(res.data)
          }
        })
        .catch(err =>{ alert('ERROR : Kindly Contact Your Adminitrator')
        setHandleSubmitBtn('') 
      })
    }
  }


  var barcodeArr = []
  var stockQuantityArr = []
  const [serviceList, setServiceList] = useState([
    {
      barcode: '',
      stockQuantity: ''
    }
  ])

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        barcode: '',
        stockQuantity: ''
      }
    ])
  }
  const handleServiceRemove = index => {
    const list = [...serviceList]
    list.splice(index, 1)
    setServiceList(list)

    list.map(val => {
      barcodeArr.push(val.barcode)
      stockQuantityArr.push(val.stockQuantity)
    })
  }
  const handleChange = (e, index) => {
    var { name, value } = e.target
    const list = [...serviceList]
    list[index][name] = value
    setServiceList(list)

    list.map(val => {
      barcodeArr.push(val.barcode)
      stockQuantityArr.push(val.stockQuantity)
    })    

    setBarcode(JSON.stringify(barcodeArr))
    setStockQuantity(JSON.stringify(stockQuantityArr))
    //alert('Barcode Value ='+barcode);
    
  }

  const [barcode, setBarcode] = useState([{}])
  const [stockQuantity, setStockQuantity] = useState([{}])

  
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Register Business New Stock -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>-->*/}
      <main>
        <form method='POST'>
          <div class='container-fluid'>
            <div class='row mt-5'>
              <div class='col-md-12 mb-2'>
                <div class='card'>
                  <div class='card-body' id='faults'>
                    <div id='divShowMessageFromAjax'></div>
                    <div class='form-header white-text ocean blue-gradient'>
                      {' '}
                      ADD STOCK HERE{' '}
                    </div>
                    {/*Start Of Row 1*/}
                    <div class='row'>
                      <div class='col-lg-4 col-md-12 mb-0.0001 text-left'>
                        <div class='grey-text'>
                          <label>
                            Data Input Method <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='dataInputMethod'
                            onChange={e => setDataInputMethod(e.target.value)}
                            autoComplete='off'
                            required
                            value={dataInputMethod}
                          >
                            <option value=''>... Choose ...</option>
                            <option>Normal Single Entry</option>
                            <option>Excel Sheet</option>
                          </select>
                        </div>
                      </div>

                      <div class='col-lg-4 col-md-12 mb-0.0001 text-left'>
                        <div class='grey-text'>
                          <label>
                            Business Name <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            class='custom-select d-block w-100'
                            id='businessName'
                            onChange={e => setBusinessName(e.target.value)}
                            autoComplete='off'
                            required
                            value={businessName}
                          >
                            <option value=''>... Choose ...</option>
                            {businessNameDropDown.map(val => {
                              return (
                                <option value={val.system_setupID}>
                                  {val.organisation}{' '}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div class='col-lg-4 col-md-12 mb-0.0001 text-left'>
                        <div class='grey-text'>
                          <label>
                            Business Branch Name <i class='red-text'>*</i>{' '}
                          </label>
                          <select
                            disabled={businessName === ''}
                            class='custom-select d-block w-100'
                            id='businessBranchName'
                            onChange={e =>
                              setBusinessBranchName(e.target.value)
                            }
                            autoComplete='off'
                            required
                            value={businessBranchName}
                          >
                            <option value=''>... Choose ...</option>
                            {businessBranchNameDropDown.map(val => {
                              return (
                                <option value={val.system_setup_branchID}>
                                  {val.branch_name}{' '}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 1*/}
                    {/*Start Of Row 2*/}
                    <div
                      class='row'
                      hidden={dataInputMethod === 'Normal Single Entry'}
                    >
                      <div class='col-lg-12 col-md-12 mb-0.0001 text-left'>
                        <div class='grey-text'>
                          <label>
                            Select Excel Sheet <i class='red-text'>*</i>{' '}
                          </label>
                          <input
                            type='file'
                            class='form-control'
                            name='excelFile'
                            id='excelFile'
                            onChange={saveExcelFile}
                            autoComplete='off'
                            required
                            //value={excelFile}
                          />
                        </div>
                      </div>

                      <div class='col-md-4 col-md-12 mb-0.0001'>
                        <div class='md-form'>
                          <br />
                          <button
                            onClick={handleSubmit}
                            class='btn btn-md btn-primary'
                            style={{ width: '100%' }}
                            disabled={
                              businessName === '' ||
                              businessBranchName === '' ||
                              excelFile === '' || 
                              handleSubmitBtn === 'Loading'
                            }
                          >
                             <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                            Submit Excel Sheet
                          </button>
                        </div>
                      </div>
                    </div>{' '}
                    {/*End Of Row 2*/}
                    <div hidden={dataInputMethod != 'Normal Single Entry'}>
                      <div class='row'>
                        <div class='col-lg-4 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Product Name<i class='red-text'></i> *
                            </label>
                            <select
                              disabled={businessName === ''}
                              class='custom-select d-block w-100'
                              id='productName'
                              onChange={e => setProductName(e.target.value)}
                              autoComplete='off'
                              required
                              value={productName}
                            >
                              <option value=''>... Choose ...</option>
                              {productNameDropDown.map(val => {
                                return (
                                  <option value={val.productsID}>
                                    {val.product}{' '}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>

                        <div class='col-lg-4 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Currency Used <i class='red-text'></i>{' '}
                            </label>
                            <select
                              class='custom-select d-block w-100'
                              autoComplete='off'
                              onChange={e => setCurrency(e.target.value)}
                              value={currency}
                            >
                              <option value=''>.. Choose ...</option>

                              <option>Kwacha</option>
                              <option>USD</option>
                              <option>Rand</option>
                              <option>Pula</option>
                              <option>Shilling</option>
                            </select>
                          </div>
                        </div>

                        <div class='col-lg-4 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Barcode/QRCode Scanner <i class='red-text'></i>{' '}
                            </label>
                            <select
                              class='custom-select d-block w-100'
                              autoComplete='off'
                              onChange={e => setScanner(e.target.value)}
                              value={scanner}
                            >
                              <option value=''>...Choose...</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>

                      </div>

                  {/*<!--Grid row--> */}
                  {serviceList.map((singleServiceList, index) => {
                    return (
                      <>
                        <div
                          class='row'
                          key={index}
                          hidden={
                            scanner === '' ||
                            scanner === 'No' ||
                            scanner === undefined
                          }
                        >
                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                {index + 1} .<span class='red-text'></span>
                              </label>
                              <br /> <br />
                            </div>
                          </div>

                          <div class='col-md-4 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span>
                                  Barcode
                                </span>
                                <span class='red-text'></span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.barcode}
                                onChange={e => handleChange(e, index)}
                                id='barcode'
                                name='barcode'
                              />
                            </div>
                          </div>

                          <div class='col-md-4 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span>
                                  Stock Quantity
                                </span>
                                <span class='red-text'></span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.stockQuantity}
                                onChange={e => handleChange(e, index)}
                                id='stockQuantity'
                                name='stockQuantity'
                              />
                            </div>
                          </div>

                          {serviceList.length > 1 && (
                            <div class='col-md-2 mb-1'>
                              <div class='md-form'>
                                <br /> <br /> <br />
                                <input
                                  type='button'
                                  class='form-control btn white-text red accent-2'
                                  value='X Remove item Barcode'
                                  onClick={handleServiceRemove}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          class='row'
                          hidden={
                            scanner === '' ||
                            scanner === 'No' ||
                            scanner === undefined
                          }
                        >
                          {serviceList.length - 1 === index && (
                            <div class='col-md-12'>
                              <div class='md-form'>
                                <button
                                  class='btn white-text blue accent-2 col-md-12'
                                  onClick={() => handleServiceAdd(index)}
                                >
                                  + Add Another Item Barcode
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  })}
                  {/*<!--\Grid row--> */}

                      <div class='row' hidden = {scanner === 'Yes'}>
                        <div class='col-lg-12 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label style={{ float: 'left' }}>
                              Stock Quantity <i class='red-text'>*</i>
                              <span
                                className={validQuantity ? 'valid' : 'hide'}
                              >
                                {' '}
                                <FontAwesomeIcon icon={faCheck} />
                              </span>{' '}
                              <span
                                className={
                                  validQuantity || !quantity
                                    ? 'hide'
                                    : 'invalid'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faTimes} />
                              </span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              id='quantity'
                              onChange={e => setQuantity(e.target.value)}
                              autoComplete='off'
                              value={quantity}
                              required
                              aria-invalid={validQuantity ? 'false' : 'true'}
                              aria-describedby='quantitynote'
                              onFocus={() => setQuantityFocus(true)}
                              onBlur={() => setQuantityFocus(false)}
                            />
                            <p
                              id='quantitynote'
                              className={
                                quantityFocus && quantity && !validQuantity
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Numbers or Figures Only <br />
                              Not Less Than 0, But Not More Than 9 Figures
                            </p>
                          </div>
                        </div>
                        </div>

                        <div class='row'>
                        <div
                          class='col-lg-4 col-md-12 mb-1 text-left'
                          hidden={currency === 'Kwacha'}
                        >
                          <div class='grey-text'>
                            <label style={{ float: 'left' }}>
                              Product Unit Cost ( {currency} )
                              <i class='red-text'>*</i>
                              <span
                                className={
                                  validProductUnitCost ? 'valid' : 'hide'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faCheck} />
                              </span>{' '}
                              <span
                                className={
                                  validProductUnitCost || !productUnitCost
                                    ? 'hide'
                                    : 'invalid'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              id='productUnitCost'
                              onChange={e => setProductUnitCost(e.target.value)}
                              autoComplete='off'
                              value={productUnitCost}
                              required
                              aria-invalid={
                                validProductUnitCost ? 'false' : 'true'
                              }
                              aria-describedby='productUnitCostnote'
                              onFocus={() => setProductUnitCostFocus(true)}
                              onBlur={() => setProductUnitCostFocus(false)}
                            />
                            <p
                              id='productUnitCostnote'
                              className={
                                productUnitCostFocus &&
                                productUnitCost &&
                                !validProductUnitCost
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Numbers or Figures Only <br />
                              Not Less Than 0, But Not More Than 9 Figures
                            </p>
                          </div>
                        </div>

                        <div
                          class='col-lg-3 col-md-12 mb-1 text-left'
                          hidden={currency === 'Kwacha'}
                        >
                          <div class='grey-text'>
                            <label style={{ float: 'left' }}>
                              Order Exchange Rate <i class='red-text'>*</i>
                              <span
                                className={
                                  validOrderExchangeRate ? 'valid' : 'hide'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faCheck} />
                              </span>{' '}
                              <span
                                className={
                                  validOrderExchangeRate || !orderExchangeRate
                                    ? 'hide'
                                    : 'invalid'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              id='orderExchangeRate'
                              onChange={e =>
                                setOrderExchangeRate(e.target.value)
                              }
                              autoComplete='off'
                              value={orderExchangeRate}
                              required
                              aria-invalid={
                                validOrderExchangeRate ? 'false' : 'true'
                              }
                              aria-describedby='orderExchangeRatenote'
                              onFocus={() => setOrderExchangeRateFocus(true)}
                              onBlur={() => setOrderExchangeRateFocus(false)}
                            />
                            <p
                              id='orderExchangeRatenote'
                              className={
                                orderExchangeRateFocus &&
                                orderExchangeRate &&
                                !validOrderExchangeRate
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Numbers or Figures Only <br />
                              Not Less Than 0, But Not More Than 9 Figures
                            </p>
                          </div>
                        </div>

                        <div
                          class='col-lg-3 col-md-12 mb-1 text-left'
                          hidden={currency === 'Kwacha'}
                        >
                          <div class='grey-text'>
                            <label style={{ float: 'left' }}>
                              Current Exchange Rate <i class='red-text'>*</i>
                              <span
                                className={
                                  validCurrentExchangeRate ? 'valid' : 'hide'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faCheck} />
                              </span>{' '}
                              <span
                                className={
                                  validCurrentExchangeRate ||
                                  !currentExchangeRate
                                    ? 'hide'
                                    : 'invalid'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faTimes} />
                              </span>{' '}
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              id='currentExchangeRate'
                              onChange={e =>
                                setCurrentExchangeRate(e.target.value)
                              }
                              autoComplete='off'
                              value={currentExchangeRate}
                              required
                              aria-invalid={validQuantity ? 'false' : 'true'}
                              aria-describedby='currentExchangeRatenote'
                              onFocus={() => setCurrentExchangeRateFocus(true)}
                              onBlur={() => setCurrentExchangeRateFocus(false)}
                            />
                            <p
                              id='currentExchangeRatenote'
                              className={
                                currentExchangeRateFocus &&
                                currentExchangeRate &&
                                !validCurrentExchangeRate
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Numbers or Figures Only <br />
                              Not Less Than 0, But Not More Than 9 Figures
                            </p>
                          </div>
                        </div>

                        <div
                          class='col-lg-10 col-md-12 mb-1 text-left'
                          hidden={currency != 'Kwacha'}
                        >
                          <div class='grey-text'>
                            <label style={{ float: 'left' }}>
                              Cost Per Product <i class='red-text'>*</i>
                              <span className={validCost ? 'valid' : 'hide'}>
                                {' '}
                                <FontAwesomeIcon icon={faCheck} />
                              </span>{' '}
                              <span
                                className={
                                  validCost || !cost ? 'hide' : 'invalid'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faTimes} />
                              </span>{' '}
                            </label>
                            <br />
                            <input
                              type='text'
                              class='form-control'
                              id='cost'
                              onChange={e => setCost(e.target.value)}
                              autoComplete='off'
                              value={cost}
                              required
                              aria-invalid={validCost ? 'false' : 'true'}
                              aria-describedby='costnote'
                              onFocus={() => setCostFocus(true)}
                              onBlur={() => setCostFocus(false)}
                            />
                            <p
                              id='costnote'
                              className={
                                costFocus && cost && !validCost
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Only Numbers or Figures Only <br />
                              Currency amount with optional thousands
                              separators, <br />
                              and two-digit fraction.
                            </p>
                          </div>
                        </div>

                        <div class='col-lg-2 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label class>
                              Product Unit Price <i class='red-text'>*</i>
                              <span className={validPrice ? 'valid' : 'hide'}>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                              <span
                                className={
                                  validPrice || !price ? 'hide' : 'invalid'
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              id='price'
                              onChange={e => setPrice(e.target.value)}
                              autoComplete='off'
                              value={price}
                              required
                              aria-invalid={validPrice ? 'false' : 'true'}
                              aria-describedby='pricenote'
                              onFocus={() => setPriceFocus(true)}
                              onBlur={() => setPriceFocus(false)}
                            />
                            <p
                              id='pricenote'
                              className={
                                priceFocus && price && !validPrice
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Only Numbers or Figures Only <br />
                              Currency amount with optional thousands
                              separators, <br />
                              and two-digit fraction.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/*Start Of Row 1*/}
                      <div class='row'>
                        <div class='col-lg-3 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Product Supplier <i class='red-text'></i>{' '}
                            </label>
                            <select
                              disabled={businessName === ''}
                              class='custom-select d-block w-100'
                              id='supplier'
                              onChange={e => setSupplier(e.target.value)}
                              autoComplete='off'
                              value={supplier}
                            >
                              <option value=''>... Choose ...</option>
                              {supplierDropDown.map(val => {
                                return (
                                  <option value={val.suppliersID}>
                                    {val.supplier_name}{' '}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>

                        <div class='col-lg-3 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Re-Order Level Type <i class='red-text'></i>{' '}
                            </label>
                            <select
                              class='custom-select d-block w-100'
                              autoComplete='off'
                              onChange={e =>
                                setReOrderLevelType(e.target.value)
                              }
                              value={reOrderLevelType}
                            >
                              <option value=''>... Choose ...</option>

                              <option>Percentage</option>
                              <option>Numbers</option>
                            </select>
                          </div>
                        </div>

                        <div class='col-lg-3 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Re-Order Level <i class='red-text'></i>
                              <span
                                className={validReOrderLevel ? 'valid' : 'hide'}
                              >
                                {' '}
                                <FontAwesomeIcon icon={faCheck} />
                              </span>{' '}
                              <span
                                className={
                                  validReOrderLevel || !reOrderLevel
                                    ? 'hide'
                                    : 'invalid'
                                }
                              >
                                {' '}
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              onChange={e => setReOrderLevel(e.target.value)}
                              value={reOrderLevel}
                              disabled={reOrderLevelType === ''}
                              autoComplete='off'
                              required
                              aria-invalid={
                                validReOrderLevel ? 'false' : 'true'
                              }
                              aria-describedby='reOrderLevelnote'
                              onFocus={() => setReOrderLevelFocus(true)}
                              onBlur={() => setReOrderLevelFocus(false)}
                            />

                            <p
                              id='reOrderLevelnote'
                              className={
                                reOrderLevelFocus &&
                                reOrderLevel &&
                                !validReOrderLevel
                                  ? 'instructions'
                                  : 'offscreen'
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Numbers or Figures Only <br />
                              Not Less Than 0, But Not More Than 9 Figures
                            </p>
                          </div>
                        </div>

                        <div class='col-lg-3 col-md-12 mb-1 text-left'>
                          <div class='grey-text'>
                            <label>
                              Product / Stoct Arrival Date{' '}
                              <i class='red-text'>*</i>{' '}
                            </label>
                            <input
                              type='date'
                              class='form-control'
                              id='arrivalDate'
                              onChange={e => setArrivalDate(e.target.value)}
                              value={arrivalDate}
                              autoComplete='off'
                              required
                            />
                          </div>
                        </div>
                      </div>{' '}
                      {/*End Of Row 1*/}
                      <p>
                        <span class='red-text'>* Required</span>{' '}
                        <span>- Do not leave Blank</span>
                      </p>
                      <hr class='mb-1' />
                      <button
                        onClick={handleSubmit}
                        class='btn btn-primary'
                        disabled={
                          businessName === '' ||
                          businessBranchName === '' ||
                          productName === '' ||
                          price === '' ||
                          arrivalDate === '' ||
                          !validPrice ||
                          currency === '' || 
                          handleSubmitBtn === 'Loading' 
                        }
                      >
                   <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                        Submit
                      </button>
                      <br /> <br /> <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  )
}

export default RegisterStock
