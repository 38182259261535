import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';

const ViewProducts = () => {
  let navigate = useNavigate()
  const handleRegisterProducts = async e => {
    navigate('/RegisterProducts')
  }
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewProducts, setDBtableViewProducts] = useState([])
  const [DBtableViewOneProduct, setDBtableViewOneProduct] = useState([])

  const [product, setProduct] = useState('')

  const [update, setUpdate] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  const secondUserPosition = JSON.parse(firstUserPosition)

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  const handleSubmitUpdate = async e => {
    //alert(update)
    //e.preventDefault()

    var formData = new FormData(this)
    formData.append('apiType', 'getProductNameDropDown')
    formData.append('productsIDToUpdate', update)
    formData.append('product', product)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
      })
      .catch(function (response) {
        //handle error
        //alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  useEffect(() => {
  /*Axios.post(`${server_app_api}/api/getViewProducts`, {
      businessNameID: systemSetupIDInitial
    })*/
  //alert(secondUserSystemSetupBranchID)
  setHandleSubmitBtn('Loading')
  var formDataProductNames = new FormData(this)
  formDataProductNames.append('apiType', 'getProductNameDropDown')
  formDataProductNames.append('businessNameID', systemSetupIDInitial)
  formDataProductNames.append(
    'systemSetupBranchID',
    secondUserSystemSetupBranchID
  )
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/index.php`,
    data: formDataProductNames,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    setHandleSubmitBtn('')
    setDBtableViewProducts(response.data)

    setTimeout(function () {
      $('#table').DataTable({
        retrieve: true, // this hides the errors
        pageLength: 10,
        lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
      
      })
      addTableButtons()
    }, 1000)
    //alert(response.data)
  })
  //.catch(err => alert('ERROR : Check Your Network Connection'))
  }, [])

  const UpdateRecord = productsID => {
    var formDataGetOneProduct = new FormData(this)
    formDataGetOneProduct.append('apiType', 'getProductNameDropDown')
    formDataGetOneProduct.append('productsID', productsID)
    formDataGetOneProduct.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataGetOneProduct,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewOneProduct(response.data)
      //alert(response.data)
    })
    setUpdate(productsID)
  }

  const deleteRecord = productID => {
    var formDataDeleteOneStock = new FormData(this)
    formDataDeleteOneStock.append('apiType', 'getProductNameDropDown')
    formDataDeleteOneStock.append('productsIDToDelete', productID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataDeleteOneStock,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      alert(response.data)
    })
  }

  /*;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()*/

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable({
        retrieve: true, // this hides the errors
        pageLength: 10,
        lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
      
      })
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    var table = $('#table').DataTable()

    new $.fn.dataTable.Buttons(table, {
      buttons: [
        {
          extend: 'copy',
          text: 'Copy <i class="fa fa-files-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3] }
        },
        {
          extend: 'excel',
          text: 'Excel <i class="fa fa-file-excel-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3] }
        },
        //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
        {
          extend: 'pdf',
          text: 'PDF <i class="fa fa-file-pdf-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3] }
        },
        {
          extend: 'print',
          text: 'Print <i class="fa fa-print"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3] }
        }
      ]
    })

    table.buttons(0, null).containers().appendTo('#filterTopCriteria')
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - View Business Products -{' '}
          {systemSetupBranchIDStoreName}{' '}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader  -->*/}
        <div hidden={handleSubmitBtn === ''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
        </div>
        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container  col-12'>
              <div class='card mt-5'>
                <div class='card-body' style={{ overflowX: 'auto' }}>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      VIEW REGISTERED PRODUCTS{' '}
                      <span style={{ float: 'right' }}>
                        {secondUserPosition === 'Business Owner' ||
                        secondUserPosition === 'Manager' ? (
                          <>
                            <a onClick={handleRegisterProducts}>
                              {' '}
                              <i class='w-fa fa fa-plus'></i> Add Products
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                    </h6>

                    {/*<!-- Modal: modalManagerLogin  -->*/}
                    <div
                      class='modal fade col-lg-12'
                      id='modalUpdateProducts'
                      tabindex='-1'
                      role='dialog'
                      aria-labelledby='myModalLabel'
                      aria-hidden='true'
                    >
                      <MDBModalDialog size='xl'>
                        {/*<!-- Content -->*/}
                        <div class='modal-content'>
                          {/* <!-- Header -->*/}
                          <div class='modal-header ocean blue-gradient white-text '>
                            <h4 class=''>Edit Products :</h4>
                            <button
                              type='button'
                              class='close waves-effect waves-light white-text'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
                          </div>
                          {/*<!-- Body -->*/}
                          <div class='modal-body mb-0 '>
                            {DBtableViewOneProduct.map(valOneProduct => {
                              return (
                                <>
                                  <table class='' style={{ width: '100%' }}>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Old Product Name{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              value={valOneProduct.product}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>{' '}
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Enter New Product Name{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              onChange={e =>
                                                setProduct(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {/*End Of Row 3*/}
                                    <tr>
                                      {' '}
                                      <td colspan='2'>
                                        <button
                                          class='btn btn-primary'
                                          onClick={handleSubmitUpdate}
                                          disabled={product === ''}
                                        >
                                          Update
                                        </button>
                                      </td>
                                    </tr>
                                  </table>
                                </>
                              )
                            })}
                          </div>
                          {/*Modal Body Ends Here*/}
                        </div>
                        </MDBModalDialog>
                      {/*<!-- Content -->*/}
                    </div>
                    {/* <!-- Modal: modalManager .-->*/}

                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm nowrap'
                      width='100%'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                        <tr>
                          {/*
SELECT `productsID`, `system_setupID`, `product`, `product_entered_by`,
 `product_date`, `product_time`, `product_IP` FROM `products` WHERE 1
*/}
                          <th>S/N</th>
                          {/*<th>Company</th>*/}
                          <th>Product Name</th>
                          <th>Total Quantities</th>
                          <th>Active Quantities</th>
                          {secondUserPosition === 'Manager' ||
                          secondUserPosition === 'Business Owner' ? (
                            <>
                              <th>UPDATE</th>
                              <th>DELETE</th>
                            </>
                          ) : (
                            <></>
                          )}
                        </tr>
                      </thead>
                      <tbody class='text-left'>
                        {DBtableViewProducts.map((val, index) => {
                          return (
                            <tr>
                              {/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
                              <td>{index + 1}</td>
                              {/*<td>{val.organisation}</td>*/}
                              <td>{val.product}</td>
                              <td>
                              <NumericFormat value={val.initial_stock_quantity} displayType={'text'} thousandSeparator={true} prefix={''} />
                              </td>
                              <td>
                              <NumericFormat value={val.stock_quantity} displayType={'text'} thousandSeparator={true} prefix={''} />
                              </td>

                              {secondUserPosition === 'Manager' ||
                              secondUserPosition === 'Business Owner' ? (
                                <>
                                  <td>
                                    {' '}
                                    <span
                                      onClick={() => {
                                        UpdateRecord(val.productsID)
                                      }}
                                      type='button'
                                      class='fa fa-edit blue-text'
                                      title='Update This Record'
                                      data-toggle='modal'
                                      data-target='#modalUpdateProducts'
                                    ></span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        deleteRecord(val.productsID)
                                      }}
                                      type='button'
                                      class='fa fa-trash red-text'
                                      title='Delete This Record From The Database'
                                    ></span>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default ViewProducts
