import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const CloseStoreSales = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if (loggedIn === 'No' || loggedIn ==='') {
    navigate('/Logout')
  }

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()
  //let businessStoreName = ''
  //let balanceAfterExpense = ''
  const [businessStoreName, setBusinessStoreName] = useState('')
  const [balanceAfterExpense, setBalanceAfterExpense] = useState('')

  var [cashBanked, setCashBanked] = useState('')
  var [cashHandedIn, setCashHandedIn] = useState('')
  //const [increaseOrDecrease, setIncreseOrDecrease] = useState([])
  const [ip, setIP] = useState('')
  const [reason, setReason] = useState('')
  var [businessStoreNameDropDown, setBusinessStoreNameDropDown] = useState([])

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  /*################### The values of the input fields manipulation Starts here ######################*/
  if (cashBanked === '') {
    cashBanked = 0
  }
  if (cashHandedIn === '') {
    cashHandedIn = 0
  }
  let increaseOrDecrease = 0
  let cashBankedCashHandedIn = 0
  cashBankedCashHandedIn = parseInt(cashBanked) + parseInt(cashHandedIn)

  increaseOrDecrease = balanceAfterExpense - cashBankedCashHandedIn
  increaseOrDecrease = increaseOrDecrease * -1
  if (cashBanked === 0) {
    cashBanked = ''
  }
  if (cashHandedIn === 0) {
    cashHandedIn = ''
  }
  /*################### The values of the input fields manipulation Ends here ######################*/

  /*################### The Disabling Or Enabling of the close button Starts here ######################*/
  let disabled = 'Yes'
  if (
    (increaseOrDecrease != 0 && reason === '') ||
    businessStoreName === '' ||
    (cashBanked === '' && cashHandedIn === '')
  ) {
    disabled = 'Yes'
  } else {
    disabled = ''
  }
  /*################### The Disabling Or Enabling of the close button ends here ######################*/
  //const [success, setSuccess ] = useState(false);

  useEffect(() => {
    businessNameRef.current.focus()
  }, [])

  /*useEffect( () => {
	alert("we are in drops");
},
[businessNameDropDown])*/

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  /*useEffect( () => {
        Axios.post("http://localhost:3001/api/getBusinessNameDropDown").then((response) => {
            setBusinessNameDropDown(response.data);
            //console.log(response);
            });
        },
        [])*/
  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)
  //alert('id = ' + systemSetupIDInitial)

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getBusinessBranchNameDropDown')
    if (secondUserSystemSetupBranchID === '') {
      formDataViewBusinesses.append('businessNameID', systemSetupIDInitial)
    }
    if (secondUserSystemSetupBranchID != '') {
      formDataViewBusinesses.append(
        'businessBranchNameID',
        secondUserSystemSetupBranchID
      )
    }
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessStoreNameDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getBalanceAfterExpenses')
    formDataViewBusinesses.append('businessBranchNameID', businessStoreName)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //alert(response.data)
      setBalanceAfterExpense(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [businessStoreName])

  /*useEffect(() => {
    var formDataViewBusinessesStoreExpenses = new FormData(this)
    formDataViewBusinessesStoreExpenses.append('apiType', 'getStoreExpenses')
    formDataViewBusinessesStoreExpenses.append(
      'businessNameID',
      systemSetupIDInitial
    )
    formDataViewBusinessesStoreExpenses.append(
      'businessBranchNameID',
      businessStoreName
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinessesStoreExpenses,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setIncreseOrDecrease(response.data)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [businessStoreName])*/

  const handleSubmit = async e => {
    //alert("businessName = "+businessName);
    const currentDate = new Date()
    const dateToday = `${currentDate.getDate()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getFullYear()}`
    const timeNow =
      currentDate.getHours() +
      ':' +
      currentDate.getMinutes() +
      ':' +
      currentDate.getSeconds()

      setHandleSubmitBtn('Loading')
    const firstUserID = localStorage.getItem('userID')
    const userIDInitial = JSON.parse(firstUserID)
    var userID = userIDInitial
    var formDataRegisterProducts = new FormData(this)
    formDataRegisterProducts.append('apiType', 'postStoreExpensesDayClose')
    formDataRegisterProducts.append('system_setupID', systemSetupIDInitial)
    formDataRegisterProducts.append('system_setup_branchID', businessStoreName)
    formDataRegisterProducts.append('store_expenses_cash_banked', cashBanked)
    formDataRegisterProducts.append(
      'store_expenses_cash_handed_in',
      cashHandedIn
    )
    formDataRegisterProducts.append('increaseOrDecrease', increaseOrDecrease)
    formDataRegisterProducts.append('balanceAfterExpense', balanceAfterExpense)
    formDataRegisterProducts.append('reason', reason)
    formDataRegisterProducts.append('store_user', secondUserSystemSetupBranchID)
    formDataRegisterProducts.append(
      'store_expenses_day_close_enteredBy',
      userID
    )
    formDataRegisterProducts.append('system_setup_IP', ip)
    /*
    $store_expenseID = $_POST['store_expenseID'];
    $store_expense_type_other = $_POST['store_expense_type_other'];
    $store_expenses_amount = $_POST['store_expenses_amount'];
    $payment_type = $_POST['payment_type'];
    $store_expenses_enteredBy = $_POST['store_expenses_enteredBy'];
    */
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRegisterProducts,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        setHandleSubmitBtn('')
        if (
          res.data === 'Successfully Uploaded' ||
          res.data ===
            'Successfully Uploaded, But Duplicate Entries where not Registered'
        ) {
          setCashBanked('')
          setCashHandedIn('')
          setReason('')
          setBusinessStoreName('')
          //setBusinessStoreNameDropDown([])
        }
        alert(res.data)
      })
      .catch(err => alert(err)) //'ERROR : Kindly Contact Your Adminitrator'))
  }

  // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(); //output= 2020-5-24
  //var time = new Date().toTimeString();

  //const time = `${currentDate.getTime()}`;
  //var today = new Date(),
  //alert("Time Is = "+timeNow+" Today's Date = "+date);

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  return (
    <>
      <title>KANCH INNOVATIVE TECHNOLOGIES - Close Store Sales</title>
      <>
        <Headers />
      </>

      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>-->*/}

      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row mt-5'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body'>
                  <div class='form-header white-text ocean blue-gradient'>
                    {' '}
                    CLOSE STORE SALES HERE{' '}
                  </div>
                  {/*Start Of Row 1*/}
                  <div class='row'>
                    <div class='col-lg-4 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Business Store Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          id='businessName'
                          onChange={e => setBusinessStoreName(e.target.value)}
                          autoComplete='off'
                          required
                          ref={businessNameRef}
                        >
                          <option value=''>..Choose Business Store ..</option>
                          {businessStoreNameDropDown.map(val => {
                            return (
                              <option value={val.system_setup_branchID}>
                                {val.branch_name}{' '}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Balance After Expenses <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          class='form-control'
                          type='text'
                          autoComplete='off'
                          value={balanceAfterExpense}
                        />
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Cash Banked <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          onChange={e => setCashBanked(e.target.value)}
                          value={cashBanked}
                          autoComplete='off'
                        />
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Cash Handed In <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          onChange={e => setCashHandedIn(e.target.value)}
                          autoComplete='off'
                          value={cashHandedIn}
                        />
                      </div>
                    </div>

                    <div class='col-lg-2 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Increase / Decrease <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          //onChange={e => setIncreseOrDecrease(e.target.value)}
                          value={increaseOrDecrease}
                          autoComplete='off'
                        />
                      </div>
                    </div>
                  </div>{' '}
                  <div class='row' hidden={increaseOrDecrease === 0}>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Reason (Please Specify Why There Is An
                          Increase/Decrease) <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <textarea
                          class='form-control'
                          onChange={e => setReason(e.target.value)}
                          autoComplete='off'
                          value={reason}
                        ></textarea>
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 1*/}
                  <p>
                    <span class='red-text'>* Required</span>{' '}
                    <span>- Do not leave Blank</span>
                  </p>
                  <hr class='mb-1' />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-primary'
                    disabled={disabled === 'Yes' || handleSubmitBtn ==='Loading'}
                  >
                    <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                    Close
                  </button>
                  <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default CloseStoreSales
