import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import { SpatialTracking } from '@mui/icons-material'

const ViewSuppliers = () => {
  let navigate = useNavigate()
  const handleRegisterSuppliers = async e => {
    navigate('/RegisterSuppliers')
  }

  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewSuppliers, setDBtableViewSuppliers] = useState([])

  const [update, setUpdate] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  const secondUserPosition = JSON.parse(firstUserPosition)

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  //alert("id = "+systemSetupIDInitial);
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getViewSuppliers`, {
      businessNameID: systemSetupIDInitial
    })*/
    setHandleSubmitBtn('Loading')
    var formDataProductSupplier = new FormData(this)
    formDataProductSupplier.append('apiType', 'getSupplierDropDown')
    formDataProductSupplier.append('businessNameID', systemSetupIDInitial)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataProductSupplier,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setDBtableViewSuppliers(response.data)

      setTimeout(function () {
        $('#table').DataTable()
        addTableButtons()
      }, 1000)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const UpdateRecord = system_setupID => {
    Axios.put(`${server_app_api}/api/update`, {
      id: system_setupID,
      lname: update
    })
    setUpdate('')
  }

  const deleteRecord = system_setupID => {
    Axios.delete(`${server_app_api}/api/delete/${system_setupID}`)
    alert('Successfully Deleted Record')
  }

  /*;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()*/

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable()
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    var table = $('#table').DataTable()

    new $.fn.dataTable.Buttons(table, {
      buttons: [
        {
          extend: 'copy',
          text: 'Copy <i class="fa fa-files-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4] }
        },
        {
          extend: 'excel',
          text: 'Excel <i class="fa fa-file-excel-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4] }
        },
        //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
        {
          extend: 'pdf',
          text: 'PDF <i class="fa fa-file-pdf-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4] }
        },
        {
          extend: 'print',
          text: 'Print <i class="fa fa-print"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4] }
        }
      ]
    })

    table.buttons(0, null).containers().appendTo('#filterTopCriteria')
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - View Business Suppliers -{' '}
          {systemSetupBranchIDStoreName}{' '}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader -->*/}
        <div hidden={handleSubmitBtn ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
        </div>

        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container col-12'>
              <div class='card mt-5'>
                <div class='card-body'>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      VIEW REGISTERED SUPPLIERS{' '}
                      <span style={{ float: 'right' }}>
                        {' '}
                        {secondUserPosition === 'Business Owner' ? (
                          <>
                            <a onClick={handleRegisterSuppliers}>
                              {' '}
                              <i class='w-fa fa fa-plus'></i> Add Suppliers
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                    </h6>
                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                        <tr>
                          {/*
SELECT `suppliersID`, `system_setupID`, `supplier_name`, `supplier_address`,
 `supplier_email`, `supplier_phone`, `supplier_date`, `supplier_time`, 
 `supplier_entered_by`, `supplier_IP` FROM `suppliers` WHERE 1
*/}
                          <th>S/N</th>
                          {/*<th>Company</th>*/}
                          <th>supplier name</th>
                          <th>supplier address</th>
                          <th>supplier email</th>
                          <th>supplier phone</th>
                          <th>UPDATE</th>
                          <th>DELETE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewSuppliers.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {/*<td>{val.organisation}</td>*/}
                              <td>{val.supplier_name}</td>
                              <td>{val.supplier_address}</td>
                              <td>{val.supplier_email}</td>
                              <td>{val.supplier_phone}</td>
                              <td>
                                {' '}
                                <span
                                  onClick={() => {
                                    UpdateRecord(val.suppliersID)
                                  }}
                                  type='button'
                                  class='fa fa-edit blue-text'
                                  title='Update This Record'
                                ></span>
                              </td>
                              <td>
                                <span
                                  onClick={() => {
                                    deleteRecord(val.suppliersID)
                                  }}
                                  type='button'
                                  class='fa fa-trash red-text'
                                  title='Delete This Record From The Database'
                                ></span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default ViewSuppliers
