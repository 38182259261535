import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../App.css'
import Headers from './Headers'
import Axios from '../api/axios'

const RegisterBusinessBranch = () => {
  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  const [branch, setBranch] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [businessNameDropDown, setBusinessNameDropDown] = useState([])
  const [address, setAddress] = useState('')
  const [ip, setIP] = useState('')

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);
  useEffect(() => {
    businessNameRef.current.focus()
  }, [])

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)
  //alert("id = "+systemSetupIDInitial);

  useEffect(() => {
    var formDataViewBusinesses = new FormData(this)
    formDataViewBusinesses.append('apiType', 'getViewBusinesses')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinesses,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      /*Axios.post(`${server_app_api}/api/getBusinessNameDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
      .then(response => {
        setBusinessNameDropDown(response.data)
      }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const handleSubmit = async e => {
    /*Axios.post(`${server_app_api}/api/insertRegisterBusinessBranch`, {
      businessName: businessName,
      branch: branch,
      address: address,
      system_setup_branch_IP: ip
    })*/
    var formDataRegisterBusinessBranch = new FormData(this)
    formDataRegisterBusinessBranch.append(
      'apiType',
      'insertRegisterBusinessBranch'
    )
    formDataRegisterBusinessBranch.append('businessName', businessName)
    formDataRegisterBusinessBranch.append('branch', branch)
    formDataRegisterBusinessBranch.append('address', address)
    formDataRegisterBusinessBranch.append('system_setup_branch_IP', ip)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataRegisterBusinessBranch,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        alert(res.data)
      })
      .catch(err => alert('ERROR : Kindly Contact Your Administrator'))

    //alert ("Successfully Uploaded");
    /*return(
	<div class="alert alert-success"><button type="button" class="close" data-dismiss="alert">&times;</button>
Successfully Uploaded</div>
	)*/

    //console.log(user);
    //console.log(pwd);
    //alert ("Successfully Uploaded : " +pacraCertificateFileName);
    //alert(JSON.stringify(formData));
  }

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  return (
    <>
      <title>KANCH INNOVATIVE TECHNOLOGIES - Register Business Branch</title>
      <>
        <Headers />
      </>
      {/*<!-- Preloader 
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>-->*/}
      <main>
        {/*<form method="POST" encType="multipart/form-data">*/}
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-md-12 mb-2'>
              <div class='card'>
                <div class='card-body' id='faults'>
                  <div id='divShowMessageFromAjax'></div>
                  <p
                    ref={errRef}
                    className={errMsg ? 'errmsg' : 'offscreen'}
                    aria-live='assertive'
                  >
                    {' '}
                    {errMsg}{' '}
                  </p>
                  <div class='form-header white-text ocean blue-gradient'>
                    {' '}
                    REGISTER BUSINESS BRANCH HERE{' '}
                  </div>
                  <input
                    type='hidden'
                    class='form-control'
                    id='ip'
                    value={ip}
                  />
                  <div class='row'>
                    <div class='col-lg-6 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label>
                          Business Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <br />
                        <select
                          class='custom-select d-block w-100'
                          id='businessName'
                          onChange={e => setBusinessName(e.target.value)}
                          autoComplete='off'
                          required
                          ref={businessNameRef}
                        >
                          <option value=''>Choose Business Name ..</option>
                          {businessNameDropDown.map(val => {
                            return (
                              <option value={val.system_setupID}>
                                {val.organisation}{' '}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div class='col-lg-6 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Branch Name <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <input
                          type='text'
                          class='form-control'
                          id='branch'
                          onChange={e => setBranch(e.target.value)}
                          autoComplete='off'
                          value={branch}
                          required
                        />
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 2*/}
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 mb-1'>
                      <div class='md-form'>
                        <label for='registerFullName'>
                          Address <i class='red-text'>*</i>{' '}
                        </label>
                        <br />
                        <textarea
                          type='text'
                          class='form-control'
                          id='address'
                          onChange={e => setAddress(e.target.value)}
                          autoComplete='off'
                          value={address}
                          required
                        />
                      </div>
                    </div>
                  </div>{' '}
                  {/*End Of Row 4*/}
                  <br />
                  <button
                    onClick={handleSubmit}
                    class='btn btn-block btn-primary'
                    disabled={branch === '' || address === ''}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*</form>*/}
      </main>
    </>
  )
}

export default RegisterBusinessBranch
