import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'

//const USER_REGEX = /^[a-zAZ][a-zA-Z0-9_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/

const ForgotPassword = () => {
  let navigate = useNavigate()
  const handleLogin = async e => {
    navigate('/')
  }

  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      //navigate('/Logout')
    }
  }, [])

  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const businessNameRef = useRef()
  const errRef = useRef()

  const [handleButtonsStatus, setHandleButtons] = useState('')
  const [otp, setOldPassword] = useState('')
  const [ip, setIP] = useState('')
  const firstUserID = localStorage.getItem('userID')
  const userIDInitial = JSON.parse(firstUserID)
  const [userID, setUserID] = useState(userIDInitial)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [validNewPassword, setValidNewPassword] = useState(false)
  const [newPasswordFocus, setNewPasswordFocus] = useState(false)

  const [matchRepeatNewPassword, setMatchRepeatNewPassword] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  //const [success, setSuccess ] = useState(false);

  useEffect(() => {
    // storing input name
    //localStorage.setItem("ruth", JSON.stringify(businessName));
  }, [])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    const result = PWD_REGEX.test(newPassword)
    setValidNewPassword(result)
    const match = newPassword === matchRepeatNewPassword
    setValidMatch(match)
  }, [newPassword, matchRepeatNewPassword])

  useEffect(() => {
    setErrMsg('')
  }, [newPassword, email])

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await Axios.get('https://geolocation-db.com/json/')
    console.log(res.data)
    setIP(res.data.IPv4)
    setTimeout(() => {
      //console.log('IP = ' + res.data.IPv4)
    }, 3000)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const firstUserOrganisation = localStorage.getItem('userOrganisation')
  const UserOrganisation = JSON.parse(firstUserOrganisation)

  //alert(email)

  const handleSubmitRequestOTP = async e => {
    e.preventDefault()
    var formData = new FormData(this)

    formData.append('apiType', 'forgotPasswordRequestOTP')
    formData.append('email', email)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/updateUserPassword.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
        if (response.data === 'OTP Sent To Your Email') {
          setHandleButtons(response.data)
        }
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  const handleSubmitConfirmOTP = async e => {
    e.preventDefault()
    var formData = new FormData(this)

    formData.append('apiType', 'forgotPasswordConfirmOTP')
    formData.append('otp', otp)
    formData.append('email', email)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/updateUserPassword.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
        if (response.data === 'OTP Is Correct') {
          setHandleButtons(response.data)
        }
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  const handleSubmitChangePasssowrd = async e => {
    e.preventDefault()
    var formData = new FormData(this)

    formData.append('apiType', 'forgotPasswordChangePassowrd')
    formData.append('newPassword', newPassword)
    formData.append('email', email)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/updateUserPassword.php',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
        if (response.data === 'Password Updated Successfully') {
          setHandleButtons(response.data)
        }
      })
      .catch(function (response) {
        //handle error
        alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  ;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()

  return (
    <>
      <title>KANCH INNOVATIVE TECHNOLOGIES - Forgot Password</title>
     
      {/*<!-- Preloader -->*/}
      <div id='overlayer'></div>
      <div class='loader'>
        <div class='spinner-border' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>
      {/* <!-- Main layout -->*/}
      <main>
        <div class='row'>
          <div class='col-xl-5 col-lg-6 col-md-12 col-sm-12 mx-auto mt-lg-5'>
            {/*} <!-- Form with header --> */}
            <div class='card wow fadeInRight' data-wow-delay='1s'>
              <div class='card-body'>
                {/*} <!-- Header -->*/}
                <div class='form-header' style={{ backgroundColor: 'red' }}>
                  <h3>
                    <i class='fa fa-key mt-2 mb-2'></i> FORGOT PASSWORD
                  </h3>
                </div>

                <form method='post'>
                  {handleButtonsStatus != 'Password Updated Successfully' ? (
                    <>
                      <div class='col-lg-12 col-md-12 mb-4'>
                        <div class='md-form mb-1'>
                          <label for='orangeForm-email'>
                            Email
                            <span className={validEmail ? 'valid' : 'hide'}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span
                              className={
                                validEmail || !email ? 'hide' : 'invalid'
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </label>
                          <input
                            type='text'
                            class='form-control'
                            Placeholder=''
                            name='email'
                            required
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            aria-invalid={validEmail ? 'false' : 'true'}
                            aria-describedby='emailnote'
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          />
                          <p
                            id='emailnote'
                            className={
                              emailFocus && email && !validEmail
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters. <br />
                            Let It Be A Valid Email Address.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {handleButtonsStatus === 'OTP Sent To Your Email' ? (
                    <>
                      <div class='col-lg-12 col-md-12 mb-4'>
                        <div class='md-form'>
                          <label for='orangeForm-pass'>
                            Enter 4-Digits OTP
                          </label>
                          <input
                            type='password'
                            class='form-control'
                            Placeholder=''
                            name='oldPassword'
                            required
                            onChange={e => setOldPassword(e.target.value)}
                            value={otp}
                            title='Enter The One Time Password (OTP) Which 
                        Was Sent To Your Email.
                        If You Have Not Received Your OTP, 
                        Check In Your Email Spam Box, If Non, 
                        Then Click On Resend Button Below to
                         Receive New OTP.'
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {handleButtonsStatus === 'OTP Is Correct' ? (
                    <>
                      <div class='col-lg-12 col-md-12 mb-4'>
                        <div class='md-form'>
                          <label for='orangeForm-pass'>
                            New Password
                            <span
                              className={validNewPassword ? 'valid' : 'hide'}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span
                              className={
                                validNewPassword || !newPassword
                                  ? 'hide'
                                  : 'invalid'
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </label>
                          <input
                            type='password'
                            class='form-control'
                            Placeholder=''
                            name='newPassword'
                            required
                            onChange={e => setNewPassword(e.target.value)}
                            value={newPassword}
                            aria-invalid={validNewPassword ? 'false' : 'true'}
                            aria-describedby='newPasswordNote'
                            onFocus={() => setNewPasswordFocus(true)}
                            onBlur={() => setNewPasswordFocus(false)}
                          />
                          <p
                            id='newPasswordNote'
                            className={
                              newPasswordFocus && !validNewPassword
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Password Should be 8 to 24 characters. <br />
                            Must include uppercase and lowercase letters, a
                            number and a special character.
                            <br />
                            Allowed special characters :{' '}
                            <span aria-label='exclamation mark'>!</span>
                            <span aria-label='at symbol'>@</span>
                            <span aria-label='hashtag'>#</span>
                            <span aria-label='dollar sign'>$</span>
                            <span aria-label='percent'>%</span>
                          </p>
                        </div>
                      </div>
                      <div class='col-lg-12 col-md-12 mb-4'>
                        <div class='md-form'>
                          <label for='orangeForm-pass'>
                            Repeat New Password
                            <span
                              className={
                                validMatch && matchRepeatNewPassword
                                  ? 'valid'
                                  : 'hide'
                              }
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span
                              className={
                                validMatch || !matchRepeatNewPassword
                                  ? 'hide'
                                  : 'invalid'
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </label>
                          <input
                            type='password'
                            class='form-control'
                            Placeholder=''
                            name='repeatNewPassword'
                            required
                            onChange={e =>
                              setMatchRepeatNewPassword(e.target.value)
                            }
                            value={matchRepeatNewPassword}
                            aria-invalid={validMatch ? 'false' : 'true'}
                            aria-describedby='confirmNote'
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                          />
                          <p
                            id='confirmNote'
                            className={
                              matchFocus && !validMatch
                                ? 'instructions'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div class='text-center'>
                    {handleButtonsStatus === '' ? (
                      <>
                        <button
                          onClick={handleSubmitRequestOTP}
                          class='btn btn-primary btn-lg white-text'
                          disabled={!validEmail}
                        >
                          Request OTP
                        </button>
                      </>
                    ) : (
                      <></>
                    )}

                    {handleButtonsStatus === 'OTP Sent To Your Email' ? (
                      <>
                        <button
                          onClick={handleSubmitConfirmOTP}
                          class='btn btn-primary btn-lg white-text'
                          disabled={otp === '' || !validEmail}
                        >
                          Confirm OTP
                        </button>
                      </>
                    ) : (
                      <></>
                    )}

                    {handleButtonsStatus === 'OTP Is Correct' ? (
                      <>
                        <button
                          onClick={handleSubmitChangePasssowrd}
                          class='btn btn-primary btn-lg white-text'
                          disabled={
                            !validNewPassword === '' ||
                            !validMatch ||
                            !validEmail
                          }
                        >
                          Change Password
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {handleButtonsStatus != '' &&
                    handleButtonsStatus != 'Password Updated Successfully' &&
                    handleButtonsStatus != 'OTP Is Correct' ? (
                      <>
                        <a
                          onClick={handleSubmitRequestOTP}
                          class='green-text'
                          disabled={email === ''}
                        >
                          {' '}
                          <i class='w-fa fa fa-key'></i>Resend OTP
                        </a>
                      </>
                    ) : (
                      <></>
                    )}

                    <br />

                    <a onClick={handleLogin} class='green-text'>
                      {' '}
                      <i class='w-fa fa fa-sign-in'></i>Login
                    </a>
                    <hr />
                  </div>
                </form>
              </div>
            </div>
            {/*<!-- Form with header -->*/}
          </div>
        </div>
      </main>
      {/* <!-- Main layout -->*/}
    </>
  )
}

export default ForgotPassword
