import { useRef, useState, useEffect, useContext } from 'react'
import AuthContext from './context/AuthProvider'
import axios from './api/axios'
import Headers from './components/Headers'
import SERVER_APP_API from './components/Headers'
import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import WOW from 'wowjs';
//const dotenv = require("dotenv");
//dotenv.config();
import { useNavigate, Navigate, Routes } from 'react-router-dom'
//import {ReactSession} from 'react-client-session';
//import Session from './api/Session';
//export {Sessions} from './Login';
const LOGIN_URL = '/auth'
const Login = () => {
  let navigate = useNavigate()
  const handleForgotPassword = async e => {
    navigate('/ForgotPassword')
  }
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP
  //const server_app_api = "http://localhost:3001";
  //alert("server_app_api = "+server_app_api);
  //posTableVisibility

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  var fontSize = '200%';
  var color = 'red';
  var eyeIcon = 'fa fa-eye';
  if(changeIcon === true){
	  fontSize = '200%';
	  color = 'black';
    eyeIcon = 'fa fa-eye-slash';
  }

  localStorage.removeItem('userID')
  localStorage.removeItem('systemSetupID')
  localStorage.removeItem('systemSetupBranchID')
  localStorage.removeItem('userPosition')
  localStorage.removeItem('userRole')
  localStorage.removeItem('POSTransactionID')
  localStorage.removeItem('transactionIDPrintReceipt')
  localStorage.removeItem('transactionID')
  localStorage.removeItem('userOrganisation')
  localStorage.removeItem('userName')
  localStorage.removeItem('organisation_address')
  localStorage.removeItem('organisation_phone')
  localStorage.removeItem('organisation_email')
  localStorage.removeItem('businessCategory')
  localStorage.removeItem('organisation_address')
  localStorage.removeItem('organisation_email')
  localStorage.removeItem('organisation_phone')
  localStorage.removeItem('systemSetupBranchID')
  localStorage.removeItem('systemSetupID')
  localStorage.removeItem('userID')
  localStorage.removeItem('userName')
  localStorage.removeItem('userOrganisation')
  localStorage.removeItem('userPosition')
  localStorage.removeItem('userRole')
  localStorage.removeItem('systemSetupBranchIDStoreName')
  localStorage.removeItem('switchVarName')
  localStorage.removeItem('switchVarBranch')
  localStorage.removeItem('responseReceiptPrintTotal')
  localStorage.removeItem('responseReceiptPrint')
  localStorage.removeItem('posTableVisibility')
  localStorage.removeItem('login')
  localStorage.removeItem('businessOwnerSelectedBranchID')
  localStorage.removeItem('switchVar')
  localStorage.removeItem('arr')
  localStorage.removeItem('arrHome')
  localStorage.setItem('loggedIn', JSON.stringify('No'))
  sessionStorage.setItem('loggedIn', JSON.stringify('No'))
  localStorage.removeItem('barcode')

  sessionStorage.removeItem('reloadCount')
  sessionStorage.removeItem('reloadCountHome')

  const { setAuth } = useContext(AuthContext)
  const userRef = useRef()
  const errRef = useRef()

  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)
  //let navigate = useNavigate()
  //const [data, setData] = useState([]);
  const [data, setData] = useState('Iam Zambian')
  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  let [handleCheckConnectivity, setHandleCheckConnectivity] = useState('')
  useEffect(() => {
new WOW.WOW({
  live:false
}).init();
  }, [])

  useEffect(() => {
    setHandleCheckConnectivity('Checking Internet Connectivity')
    var formDataConnectivity = new FormData(this)
    formDataConnectivity.append('apiType', 'checkDatabaseConnectivity')
    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataConnectivity,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleCheckConnectivity('')
      if(response.data === 'SYSTEM ERROR : Kindly Contact Your Administrator'){        
      alert(response.data)
      }
    }) .catch(err =>{ alert("Check Your Internet Connectivity. It Is Either Slow Or Disconnected")
    setHandleCheckConnectivity('')
  }
    )
  }, [])
    
  useEffect(() => {
    localStorage.removeItem('userID')
    localStorage.setItem('loggedIn', JSON.stringify('No'))
    const firstUserLoggedIns = localStorage.getItem('loggedIn')
    const secondUserLoggedIns = JSON.parse(firstUserLoggedIns)
    //localStorage.removeItem('userOrganisation')
    //alert ("secondUserLoggedIns = " +secondUserLoggedIns);
    //localStorage.removeItem("loggedIn");
    /*localStorage.removeItem("systemSetupID");
				localStorage.removeItem("systemSetupBranchID");
				localStorage.removeItem("userPosition");
				localStorage.removeItem("userRole");
				localStorage.removeItem("POSTransactionID");
				localStorage.removeItem('transactionIDPrintReceipt');
				localStorage.removeItem('transactionID');
				localStorage.removeItem('userOrganisation');
				localStorage.removeItem('userName');
				
				
sessionStorage.removeItem('reloadCount');
sessionStorage.removeItem('reloadCountHome');*/
  }, [])

  /**useEffect(() => {
  const reloadCount = sessionStorage.getItem('reloadCountHome');
    if(reloadCount === null) {
      sessionStorage.setItem('reloadCountHome', String(reloadCount + 1));
      window.location.reload();
    } 	
}, []);**/

  useEffect(() => {
    localStorage.setItem('names', JSON.stringify(data))
  }, [data])

  //localStorage.setItem("surname", JSON.stringify("Kanchele"));
  //const first = localStorage.getItem("userID");
  //const second = JSON.parse(first);
  //let second="Again Rodeen In Error";
  //alert(second);

  /*
	 // To store data
localStorage.setItem('Name', 'Rahul');

// To retrieve data
localStorage.getItem('Name');

// To clear a specific item
localStorage.removeItem('Name');

// To clear the whole data stored in localStorage
localStorage.clear();	 
	 */

  useEffect(() => {
    userRef.current.focus()
  }, [])
  useEffect(() => {
    setErrMsg('')
  }, [user, pwd])

  const [ip, setIP] = useState('')

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data)
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])
  //alert(server_app_api)
  const handleSubmit = async e => {
    //  "homepage": "http://www.sims.kanchinnovativetechnologies.online/", 6585667
    //http://sims.kanchinnovativetechnologies.online/
    /*axios
      .post(`${server_app_api_php}/reactphp/login.php`, {
        //.post(`${server_app_api}/api/userLogin/`, {
        //axios.post("http://localhost:3001/api/userLogin/", {

        user: user,
        pwd: pwd,
        user_IP: ip
      })*/
    //  "homepage": "http://www.sims.kanchinnovativetechnologies.online/", 6585667
    //http://sims.kanchinnovativetechnologies.online/

    setHandleSubmitBtn('Loading')
    var formDataLogin = new FormData(this)
    formDataLogin.append('user', user)
    formDataLogin.append('pwd', pwd)
    formDataLogin.append('user_IP', ip)
    axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/login.php`,
      data: formDataLogin,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        setHandleSubmitBtn('')
        //alert(res.data);
        if (res.data === 'Super Administrator') {
          localStorage.setItem('userID', JSON.stringify('0'))
          localStorage.setItem('systemSetupID', JSON.stringify('0'))
          localStorage.setItem('systemSetupBranchID', JSON.stringify('0'))
          localStorage.setItem(
            'systemSetupBranchIDStoreName',
            JSON.stringify('0')
          )
          localStorage.setItem('userPositionProvisional', JSON.stringify(''))
          localStorage.setItem(
            'userPosition',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem(
            'userRole',
            JSON.stringify('Super Administrator')
          )
          localStorage.setItem('login', JSON.stringify('User'))
          localStorage.setItem('transactionID', JSON.stringify('0'))
          localStorage.setItem('POSTransactionID', JSON.stringify(''))
          localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0'))
          localStorage.setItem(
            'userOrganisation',
            JSON.stringify('Kanch Innovative Technologies Limited')
          )
          localStorage.setItem(
            'userName',
            JSON.stringify('Super Administrator')
          )

          localStorage.setItem(
            'organisation_address',
            JSON.stringify('Lusaka-Zambia')
          )
          localStorage.setItem(
            'organisation_phone',
            JSON.stringify('+260979171188')
          )
          localStorage.setItem(
            'organisation_email',
            JSON.stringify('kanchytechnologies@gmail.com')
          )
          sessionStorage.setItem('loggedIn', JSON.stringify('Yes'))
          localStorage.setItem('loggedIn', JSON.stringify('Yes'))
          //alert("User Id = "+val.id+"  UserName = "+val.lname);
          //alert('Successfully Logged In')
          navigate('/Home')
        } else if (res.data != 'Failed To Login, Check Your Credentials') {
          /*res.data.map(val => {
            localStorage.setItem('userID', JSON.stringify(val.usersID))
            localStorage.setItem(
              'systemSetupID',
              JSON.stringify(val.system_setupID)
            )
            localStorage.setItem(
              'systemSetupBranchID',
              JSON.stringify(val.system_setup_branchID)
            )
            localStorage.setItem('userPosition', JSON.stringify(val.position))
            localStorage.setItem('userRole', JSON.stringify(val.role))
            localStorage.setItem('login', JSON.stringify('User'))
            localStorage.setItem('transactionID', JSON.stringify('0'))
            localStorage.setItem('POSTransactionID', JSON.stringify(''))
            localStorage.setItem(
              'transactionIDPrintReceipt',
              JSON.stringify('0')
            )
            localStorage.setItem(
              'userOrganisation',
              JSON.stringify(val.organisation)
            )
            localStorage.setItem(
              'userName',
              JSON.stringify(val.Last_name + '  ' + val.first_name)
            )

            localStorage.setItem(
              'organisation_address',
              JSON.stringify(val.organisation_address)
            )
            localStorage.setItem(
              'organisation_phone',
              JSON.stringify(val.organisation_phone)
            )
            localStorage.setItem(
              'organisation_email',
              JSON.stringify(val.organisation_email)
            )
            localStorage.setItem('loggedIn', JSON.stringify('Yes'))
            //alert("User Id = "+val.id+"  UserName = "+val.lname);
            //alert('Successfully Logged In')
            navigate('/Home')
          })
          //alert("Successfully Logged In") */
          //res.data.map(val => {
          localStorage.setItem('userID', JSON.stringify(res.data.userID))
          localStorage.setItem(
            'systemSetupID',
            JSON.stringify(res.data.systemSetupID)
          )
          localStorage.setItem(
            'systemSetupBranchID',
            JSON.stringify(res.data.systemSetupBranchID)
          )
          localStorage.setItem(
            'systemSetupBranchIDStoreName',
            JSON.stringify(res.data.systemSetupBranchIDStoreName)
          )
          localStorage.setItem('userPositionProvisional', JSON.stringify(''))
          localStorage.setItem(
            'userPosition',
            JSON.stringify(res.data.userPosition)
          )
          localStorage.setItem('userRole', JSON.stringify(res.data.userRole))
          localStorage.setItem('login', JSON.stringify('User'))
          localStorage.setItem('transactionID', JSON.stringify('0'))
          localStorage.setItem('POSTransactionID', JSON.stringify(''))
          localStorage.setItem('transactionIDPrintReceipt', JSON.stringify('0'))
          localStorage.setItem(
            'userOrganisation',
            JSON.stringify(res.data.userOrganisation)
          )
          localStorage.setItem('userName', JSON.stringify(res.data.userName))

          localStorage.setItem(
            'organisation_address',
            JSON.stringify(res.data.organisation_address)
          )
          localStorage.setItem(
            'organisation_phone',
            JSON.stringify(res.data.organisation_phone)
          )
          localStorage.setItem(
            'organisation_email',
            JSON.stringify(res.data.organisation_email)
          )
          sessionStorage.setItem('loggedIn', JSON.stringify('Yes'))
          localStorage.setItem('loggedIn', JSON.stringify('Yes'))
          localStorage.setItem(
            'businessCategory',
            JSON.stringify(res.data.businessCategory)
          )
          //alert("User Id = "+val.id+"  UserName = "+val.lname);
          //alert('Successfully Logged In')
          //alert(res.data.businessCategory)
          navigate('/Home')
          //})*/
        } else {          
          alert(res.data)
        }
      })
      .catch(err => {alert('ERROR : Kindly Contact Your Administrator')
      setHandleSubmitBtn('')
    })
    //.catch((err) => alert(err));
  }

  //Session.setName("Some Guy");
  //alert(Session.getName());
  /*var Sessions = (function() {
    var full_name;
  
    var getName = function() {
      return full_name="my name of rodeen";    // Or pull this from cookie/localStorage
    };
  
    var setName = function(name) {
      full_name = name;     
      // Also set this in cookie/localStorage
    };
  //ReactSession.setStoreType("localStorage");
    return {
      getName: getName,
      setName: setName
    }
  
  })();*/

  /*(function () {
  

    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()
*/
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES SHOP INFORMATION MANAGEMENT SYSTEM
      </title>
      
      {/*<!-- Preloader -->*/}
      <div hidden={handleCheckConnectivity ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
        <div class="spinner-border text-danger" style={{width: '4rem', height: '4rem'}} role="status">
</div>
      <div class="spinner-grow text-primary" style={{width: '4rem', height: '4rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
        </div>
        </div>

      <section class='view intro-2' id='login'>
        <div
          class=' rgba-indigo-light h-100 d-flex justify-content-center align-items-center'
          id='mask'
        >
          <div class='container'>
            <div class='row'>
              <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto mt-5'>
                <div class='card wow fadeIn' data-wow-delay='0.3s'>
                  <div
                    class='card-body'
                    id='card-body'
                    style={{ background: 'transparent' }}
                  >
                    <div class='form-header ocean blue-gradient'>
                      <h3 class='font-weight-500 my-2 py-1'>
                        KANCH INNOVATIVE TECHNOLOGIES
                      </h3>
                    </div>

                    {/*</form>*/}
                    {/*< -- Grid row --> */}
                    <div class='row mt-5'>
                      {/*< -- Grid column --> */}
                      <div class='col-md-6 ml-lg-5 ml-md-5'>
                        {/*< -- Grid column --> */}
                        <div class='col-xl-12 col-md-6 mb-1'>
                          {/*< -- Panel --> */}
                          <div
                            class='card wow fadeInLeft'
                            data-wow-delay='0.8s'
                          >
                            {/*<div
                              class='card-header white-text'
                              style={{ backgroundColor: '#0C3823' }}
                            >
                              DETAILS
                            </div> */}

                            {/*< -- Card Data --> */}

                            {/*< -- Card content --> */}
                            <div class='card-body'>
                              <div class=''>
                                {/*< -- Grid row --> */}
                                <div class='row'>
                                  <div class='col-2 col-lg-1'>
                                    {/*< --<i class="fa fa-company indigo-text fa-lg"></i>-->*/}
                                  </div>
                                  <div class='col-12'>
                                    <h5
                                      class='font-weight-bold mb-1'
                                      style={{ color: '#2E3192' }}
                                    >
                                      <strong>
                                        <u>
                                          SHOP INFORMATION MANAGEMENT SYSTEM
                                        </u>
                                      </strong>
                                    </h5>
                                    <p></p>
                                  </div>
                                </div>
                                {/*< -- Grid row --> */}

                                {/*< -- Grid row --> */}
                                <div class='row '>
                                  <div class='col-2 col-lg-1'>
                                    {/*< --<i class="fa fa-company indigo-text fa-lg"></i>-->*/}
                                  </div>
                                  <div class='col-12'>
                                    <h5
                                      class='font-weight-bold mb-1'
                                      style={{ color: '#2E3192' }}
                                    >
                                      <strong>
                                        <u>CONTACT US</u>
                                      </strong>
                                    </h5>
                                    <p><i class="fa fa-phone mr-3"></i>+260 972 898 813</p>
                                    <p><i class="fa fa-phone mr-3"></i>+260 969 536 263</p>
                                    <p><i class="fa fa-phone mr-3"></i>+260 760 040 837</p>
                                    <p><i class="fa fa-phone mr-3"></i>+260 979 171 188</p>
                                    <p><i class="fa fa-envelope mr-1"></i><i>info@kanchtech.com</i></p>
                                    <p><i class="fa fa-envelope mr-1"></i><i>sales@kanchtech.com</i></p>
                                    <p><i class="fa fa-envelope mr-1"></i><i>support@kanchtech.com</i></p>
                                  </div>
                                </div>
                                {/*< -- Grid row --> */}
                              </div>
                            </div>
                            {/*< -- Card content --> */}
                          </div>
                          {/*< -- Panel --> */}
                        </div>
                        {/*< -- Grid column --> */}
                      </div>
                      {/*< -- Grid column --> */}

                      {/*< -- Grid column --> */}
                      <div
                        class='col-md-5 wow fadeInDown'
                        data-wow-delay='0.3s'
                        
                      >
                        {/*< -- Grid row --> */}
                        <div class='row pb-4 d-flex justify-content-center'>
                          <h4 class='mt-5 mr-4' style={{ color: '#2E3192' }}>
                            <strong>
                              <i class='fa fa-user'></i> LOGIN
                            </strong>
                          </h4>
                        </div>
                        {/*< -- /Grid row --> */}

                        {/*< -- Body --> */}
                        <MDBRow tag="form" className='g-3 text-left'>
                        <MDBCol md="12">
                        <label>Username</label>
                        <MDBInputGroup textBefore={<i class='fa fa-envelope prefix red-text'></i>} className='mb-3'>
        <input ref={userRef} className='form-control' type='text' onChange={e => setUser(e.target.value)} value={user} />
      </MDBInputGroup>
      </MDBCol>
      </MDBRow>

                        <MDBRow tag="form" className='g-3 text-left'>
                        <MDBCol md="12">
                          <label>Password</label>                     
      <MDBInputGroup className='mb-3' textBefore={<i class='fa fa-lock prefix red-text'></i>} textAfter= {showPassword ? <MDBIcon icon='eye-slash' style={{fontSize:'200%', color:'black'}} type='button' onClick={handleShowPassword}/> : <MDBIcon icon='eye' type='button' onClick={handleShowPassword} style={{fontSize:'200%', color:'red'}}/> }>
        <input type={showPassword ? "text" : "password"} className='form-control' onChange={e => setPwd(e.target.value)} value={pwd} />
      </MDBInputGroup>
      </MDBCol>
                    {/*<div class="col-9 col-md-9">
                        <div class='md-form text-left'>
                          <i class='fa fa-lock prefix red-text'></i>
                          <input
                            type={changePassword ? "password" : "text"}
                            class='form-control'
                            onChange={e => setPwd(e.target.value)}
                            value={pwd}
                          />
                          <label>Your password</label>
                        </div>
                        </div>

                        <div class="col-3 col-md-3 mb-5">
                      <div class='md-form'>
                      <label type='button' class={eyeIcon} style={{fontSize, color}}
                 onClick={() => { setChangePassword(changeIcon); }} > 
                 </label>
                 
                    </div>
                    </div> */}
                     </MDBRow>

                        <div class='text-center'>
                          <button
                            class='btn btn-info mt-1'
                            onClick={handleSubmit}
                            disabled={user === '' || pwd === '' || handleSubmitBtn === 'Loading'}
                          >
                    <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
                            <span class='fa fa-sign-in'> Login</span>
                          </button>
                          <br />

                          <a onClick={handleForgotPassword} class='red-text'>
                            {' '}
                            <i class='w-fa fa fa-key'></i>Forgot Password
                          </a>
                        </div>
                      </div>
                      {/*< -- Grid column --> */}
                    </div>
                    {/*< -- Grid row --> */}
                  </div>{' '}
                  {/*Card Body Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
    </>
  )
}

//export {Sessions} from './Login';
export default Login
