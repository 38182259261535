import { useState } from 'react'
import { useNavigate, Navigate, Routes } from 'react-router-dom'

//const SERVER_APP_API = 'http://localhost:3001'
//export const SERVER_APP_API = "https://sims.app.kanchinnotech.nl";
import { Audio } from 'react-loader-spinner'

const Headers = () => {
  const [isLoading, setIsloading] = useState(false)
  let navigate = useNavigate()
  const handleSubmitDashboard = async e => {
    navigate('/Home')
  }
  const handleSubmitRegisterBusiness = async e => {
    navigate('/RegisterBusiness')
  }
  const handleSubmitViewBusinesses = async e => {
    navigate('/ViewBusinesses')
  }
  const handleSubmitRegisterBusinessBranch = async e => {
    navigate('/RegisterBusinessBranch')
  }
  const handleSubmitRegisterUsers = async e => {
    navigate('/RegisterUsers')
  }
  const handleSubmitRegisterSuppliers = async e => {
    navigate('/RegisterSuppliers')
  }
  const handleSubmitRegisterStoreExpensesTypes = async e => {
    navigate('/RegisterStoreExpensesTypes')
  }
  const handleSubmitSetCustomerPoints = async e => {
    navigate('/SetCustomerPoints')
  }
  const handleSubmitRegisterProducts = async e => {
    navigate('/RegisterProducts')
  }
  const handleSubmitRegisterStock = async e => {
    navigate('/RegisterStock')
  }
  const handleSubmitRegisterStoreExpenses = async e => {
    navigate('/RegisterStoreExpenses')
  }
  const handleSubmitCloseStoreSales = async e => {
    navigate('/CloseStoreSales')
  }
  const handleSubmitViewUsers = async e => {
    navigate('/ViewUsers')
  }
  const handleSubmitViewSuppliers = async e => {
    navigate('/ViewSuppliers')
  }
  const handleSubmitViewProducts = async e => {
    navigate('/ViewProducts')
  }
  const handleSubmitViewStock = async e => {
    navigate('/ViewStock')
  }
  const handleSubmitSalesReport = async e => {
    navigate('/SalesReport')
  }
  const handleSubmitSalesDelete = async e => {
    navigate('/Sales')
  }
  const handleSubmitCashReport = async e => {
    navigate('/CashReport')
  }
  const handleSubmitMobileMoneyReport = async e => {
    navigate('/MobileMoneyReport')
  }
  const handleSubmitDebitCardReport = async e => {
    navigate('/DebitCardReport')
  }
  const handleSubmitNHIMAReport = async e => {
    navigate('/NHIMAReport')
  }
  const handleSubmitYellowReport = async e => {
    navigate('/YellowReport')
  }
  const handleSubmitXtendaReport = async e => {
    navigate('/XtendaReport')
  }
  const handleSubmitProfitReport = async e => {
    navigate('/ProfitReport')
  }

  const handleSubmitPos = async e => {
    navigate('/pos')
  }

  const handleSubmitLogout = async e => {
    navigate('/Logout')
  }
  const handleRegisterShopCategory = async e => {
    navigate('/RegisterShopCategory')
  }
  const handleSubmitTestPDF = async e => {
    navigate('/TESTPDF')
  }
  const handleSubmitUpdateProfile = async e => {
    navigate('/UpdateProfile')
  }
  const handleSubmitUpdatePassword = async e => {
    navigate('/UpdatePassword')
  }
  const handleSubmitStockTransfer = async e => {
    navigate('/StockTransfer')
  }
  const handleSubmitStoreExpenses = async e => {
    navigate('/StoreExpenses')
  }
  const handleSubmitStoreExpensesDetailed = async e => {
    navigate('/StoreExpensesDetailed')
  }
  const handleSubmitViewCustomers = async e => {
    navigate('/ViewCustomers')
  }
  const handleSubmitCompareProductSales = async e => {
    navigate('/CompareProductSales')
  }

  /*const handleSubmitDataTables = async e => {
    navigate('/TestDataTables')
  }*/

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  //alert(loggedIn)
  if (loggedIn === 'No' || loggedIn ==='' || loggedIn === undefined || loggedIn === null) {
    navigate('/Logout')
    
  }

  const firstUserLoggedIn = localStorage.getItem('loggedIn')
  const secondUserLoggedIn = JSON.parse(firstUserLoggedIn)
  //const secondUserLoggedIn = "No";

  const firstUserRole = localStorage.getItem('userRole')
  const secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  const secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserName = localStorage.getItem('userName')
  const secondUserName = JSON.parse(firstUserName)

  const firstUserOrganisation = localStorage.getItem('userOrganisation')
  const secondUserOrganisation = JSON.parse(firstUserOrganisation)

  let businessCategory = localStorage.getItem('businessCategory')
  businessCategory = JSON.parse(businessCategory)
  //const firstHeader = localStorage.getItem('userID')
  //const secondHeader = JSON.parse(firstHeader)

  /*if(secondUserLoggedIn === "No")
        {
            navigate('/Logout');
        }*/

  return (
    <>
      {secondUserLoggedIn === 'Yes' ? (
        <>
          {/*<!-- Main Navigation -->*/}
          <header>
            {/*{*/}

            {/*}*/}
            {/*<!-- Navbar -->*/}
            <nav class='navbar fixed-top navbar-expand-lg scrolling-navbar double-nav'>
              {/*<!-- SideNav slide-out button -->*
		<div class="float-left">
        <a href="#" data-activates="slide-out" class="button-collapse" onClick={button_collapse}><i class="fas fa-bars"></i></a>
  </div>*/}

              {/*<!-- Breadcrumb -->*/}
              <div class='breadcrumb-dn mr-auto'>
                <p>{secondUserOrganisation}</p>
                {/*<p>KANCHY TECHNOLOGIES BUSINESS MANAGEMENT SYSTEM</p>*/}
              </div>

              <div class='d-flex change-mode'>
                <div class='ml-auto mr-3 change-mode-wrapper'>
                  <a>
                    <button
                      class='btn  btn-sm blue-text'
                      id='dark-mode'
                      onClick={handleSubmitDashboard}
                    >
                      Dashboard
                    </button>
                  </a>
                </div>

                {/*<!-- Navbar links -->*/}
                <ul class='nav navbar-nav nav-flex-icons ml-auto'>
                  {/*
                  {secondUserRole != 'User' ? (
                    <>
                      */}
                  {secondUserPosition === 'Super Administrator' ? (
                    <>
                      <li class='nav-item dropdown notifications-nav'>
                        <a
                          class='nav-link dropdown-toggle waves-effect'
                          id='navbarDropdownMenuLink'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <i class='fa fa-cogs'></i>
                          <span class='d-none d-md-inline-block'>
                            Administration
                          </span>
                        </a>
                        <div
                          class='dropdown-menu dropdown-primary'
                          aria-labelledby='navbarDropdownMenuLink'
                        >
                          <a
                            class='dropdown-item'
                            onClick={handleRegisterShopCategory}
                          >
                            <span>Register Shop Category</span>
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitRegisterBusiness}
                          >
                            {' '}
                            <span>Register Business</span>{' '}
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitViewBusinesses}
                          >
                            {' '}
                            <span>View Registered Business</span>{' '}
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitRegisterBusinessBranch}
                          >
                            {' '}
                            <span>Register Business Branch</span>{' '}
                          </a>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {secondUserPosition === 'Super Administrator' ||
                  secondUserPosition === 'Manager' ||
                  secondUserPosition === 'Business Owner' ||
                  secondUserPosition === 'Cashier' ||
                  secondUserRole === 'System Administrator' ? (
                    <>
                      <li class='nav-item dropdown notifications-nav'>
                        <a
                          class='nav-link dropdown-toggle waves-effect'
                          id='navbarDropdownMenuLink'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <i class='fa fa-cog'></i>
                          <span class='d-none d-md-inline-block'>
                            Business Settings
                          </span>
                        </a>
                        <div
                          class='dropdown-menu dropdown-primary'
                          aria-labelledby='navbarDropdownMenuLink'
                        >
                          {secondUserPosition != 'Manager' &&
                          secondUserPosition != 'Cashier' ? (
                            <>
                              <a
                                class='dropdown-item'
                                onClick={handleSubmitRegisterUsers}
                              >
                                {' '}
                                <span>Register Users</span>{' '}
                              </a>
                              <a
                                class='dropdown-item'
                                onClick={handleSubmitRegisterSuppliers}
                              >
                                {' '}
                                <span>Register Suppliers</span>{' '}
                              </a>
                              <a
                                class='dropdown-item'
                                onClick={handleSubmitRegisterStoreExpensesTypes}
                              >
                                {' '}
                                <span>Register Store Expenses Types</span>{' '}
                              </a>
                              <a
                                class='dropdown-item'
                                onClick={handleSubmitSetCustomerPoints}
                              >
                                {' '}
                                <span>Set Customer Retention Points</span>{' '}
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                          {/*<a class="dropdown-item" href="ViewSuppliers"> <span>View Suppliers</span> </a>*/}
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitRegisterProducts}
                            hidden={secondUserPosition === 'Cashier'}
                          >
                            {' '}
                            <span>Register Products</span>{' '}
                          </a>
                          {/*<a class="dropdown-item" href="ViewProducts"> <span>View Products</span> </a>*/}
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitRegisterStock}
                            hidden={secondUserPosition === 'Cashier'}
                          >
                            {' '}
                            <span>Register Stock</span>{' '}
                          </a>
                          {/*<a class="dropdown-item" href="ViewStock"> <span>View Stock</span> </a>*/}

                          <a
                            class='dropdown-item'
                            onClick={handleSubmitRegisterStoreExpenses}
                          >
                            {' '}
                            <span>Register Store Expenses</span>{' '}
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitCloseStoreSales}
                          >
                            {' '}
                            <span>Close Store Sales</span>{' '}
                          </a>
                        </div>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  <li class='nav-item dropdown'>
                    <a
                      class='nav-link dropdown-toggle waves-effect'
                      href='#'
                      id='userDropdown'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <i class='fa fa-align-left red-text'></i>{' '}
                      <span class='clearfix d-none d-sm-inline-block'>
                        Reports
                      </span>
                    </a>
                    <div
                      class='dropdown-menu dropdown-menu-right'
                      aria-labelledby='userDropdown'
                      style={{
                        height: '',
                        width: '400%',
                        position: ''
                      }}
                    >
                      {secondUserPosition === 'Super Administrator' ||
                      secondUserPosition === 'Business Owner' ||
                      secondUserRole === 'System Administrator' ? (
                        <>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitViewUsers}
                          >
                            <i class='w-fa fa fa-eye'></i>{' '}
                            <span>Users Report</span>{' '}
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitViewSuppliers}
                          >
                            <i class='w-fa fa fa-eye'></i>{' '}
                            <span>Suppliers Report</span>{' '}
                          </a>
                        </>
                      ) : (
                        <></>
                      )}

                      {secondUserPosition != 'Cashier' ? (
                        <>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitViewProducts}
                          >
                            <i class='w-fa fa fa-eye'></i> Products Report
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitViewStock}
                          >
                            <i class='w-fa fa fa-eye'></i> Stock / Inventory
                            Report
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitSalesReport}
                      >
                        {' '}
                        <i class='w-fa fa fa-eye'></i> Sales Report (Sales Day
                        Book)
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitSalesDelete}
                      >
                        {' '}
                        <i class='w-fa fa fa-eye'></i> Sales Report (Search  &nbsp;
                       <span hidden={secondUserPosition != 'Business Owner'}> and Delete </span> 
                        )
                      </a>
                      <a class='dropdown-item' onClick={handleSubmitCashReport}>
                        <i class='w-fa fa fa-eye'></i> Cash Report
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitMobileMoneyReport}
                      >
                        <i class='w-fa fa fa-eye'></i> Mobile Money Report
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitDebitCardReport}
                      >
                        <i class='w-fa fa fa-eye'></i> Debit Card Report
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitYellowReport}
                        hidden={businessCategory != 'Phones Shop'}
                      >
                        <i class='w-fa fa fa-eye'></i> Yellow Sales Report
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitXtendaReport}
                        hidden={businessCategory != 'Phones Shop'}
                      >
                        <i class='w-fa fa fa-eye'></i> Xtenda Sales Report
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitNHIMAReport}
                        hidden={businessCategory != 'Pharmacy Shop'}
                      >
                        {' '}
                        <i class='w-fa fa fa-eye'></i> NHIMA Report
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitStockTransfer}
                      >
                        <i class='w-fa fa fa-eye'></i> Stock Transfer
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitStoreExpenses}
                      >
                        <i class='w-fa fa fa-eye'></i> Store Expenses
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitStoreExpensesDetailed}
                      >
                        <i class='w-fa fa fa-eye'></i> Store Expenses - Detailed
                      </a>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitViewCustomers}
                      >
                        <i class='w-fa fa fa-eye'></i> View Customers
                      </a>

                      {secondUserPosition === 'Super Administrator' ||
                      secondUserPosition === 'Business Owner' ||
                      secondUserRole === 'System Administrator' ? (
                        <>
                          <a
                            class='dropdown-item'
                            onClick={handleSubmitProfitReport}
                          >
                            <i class='w-fa fa fa-eye'></i> Profit Report
                          </a>

                          <a
                            class='dropdown-item'
                            onClick={handleSubmitCompareProductSales}
                          >
                            <i class='w-fa fa fa-eye'></i> Compare Product Sales
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      {/*<a class="dropdown-item" href="IncomeStatement"> <i class="w-fa fas fa-eye"></i> Income Statement (Profit and Loss Account) </a>
			<a class="dropdown-item" href="BalanceSheet"><i class="w-fa fas fa-eye"></i> Balance Sheet</a>*/}
                    </div>
                  </li>{' '}
                  {/*
                    </>
                  ) : (
                    <></>
                  )}
                  */}
                  <li class='nav-item dropdown'>
                    <a
                      class='nav-link dropdown-toggle waves-effect'
                      href='#'
                      id='userDropdown'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <i class='fa fa-user'></i>{' '}
                      <span class='clearfix d-none d-sm-inline-block'>
                        Profile
                      </span>
                    </a>
                    <div
                      class='dropdown-menu dropdown-menu-right'
                      aria-labelledby='userDropdown'
                      style={{
                        height: '',
                        width: '400%',
                        position: '',
                        right: ''
                      }}
                    >
                      <div
                        class='card-header white-text'
                        style={{ width: '100%', position: '' }}
                      >
                        <i class='fas fa-user-alt'></i> <u>{secondUserName}</u>
                      </div>
                      <a
                        class='dropdown-item'
                        onClick={handleSubmitUpdateProfile}
                      >
                        <i class='fa fa-edit green-text'></i> UPDATE PROFILE
                      </a>

                      <a
                        class='dropdown-item'
                        onClick={handleSubmitUpdatePassword}
                      >
                        <i class='fa fa-key green-text'></i> CHANGE PASSWORD
                      </a>

                      <a class='dropdown-item' onClick={handleSubmitLogout}>
                        <i class='fa fa-sign-out red-text'></i> Log Out
                      </a>

                      {/*<a class='dropdown-item' onClick={handleSubmitDataTables}>
                        <i class='fa fa-eye'></i> DataTables
                      </a>

                      <a class="dropdown-item" onClick={handleSubmitTestPDF}>Test PDF IN TCPDF</a>
        <a class="dropdown-item" href="Form1">Form 1</a>*/}
                      {/*<a class="dropdown-item" href="DataTables">Data Tables</a>*/}
                      {/*<a class="dropdown-item" href="Tesseract">Tesseract</a>
				  <a class="dropdown-item" href="CollapsibleTable">CollapsibleTable</a>
              <a class="dropdown-item" href="Profile"> <i class="fa fa-edit"></i> My Profile</a>*/}
                    </div>
                  </li>
                  <li class='nav-item'>
                    <a class='nav-link waves-effect'>
                      <i class='fa fa-usd blue-text'></i>{' '}
                      <span
                        class='clearfix d-sm-inline-block'
                        onClick={handleSubmitPos}
                      >
                        POS
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            {/*<!-- Navbar -->*/}

            {/*
    Go to WHM > List Account > Create a new Account 
Then create the cpanle account for your domain
After its created, go again to List ACcount and click the cPanel icon beside your domain
It will redirect you to the cPanel of your domain

Thanks for waiting. Here's the case ID 44986404  I'll be escalating this issue to our VPS supports. 
We have recently updated our ticketing system, where we are escalating the cases to our Tech admins 
and they will working on it straight away and they will reply you via email. With the new ticket update,
 you will no longer be able to check the tickets in your customer portal. They will update you via email.
    */}
            <br />
            <br />
            <br />
          </header>
        </>
      ) : (
        <></>
      )}

      {/*
<center>
<div class="" style={{position:'fixed',zIndex:'1' ,display: 'flex', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>

<Audio
  height="80"
  width="80"
  radius="9"
  color="green"
  ariaLabel="loading"
  wrapperStyle
  wrapperClass
/>
</div>
</center>*/}
    </>
  )
}

export default Headers
