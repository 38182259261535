import { useRef, useState, useEffect, useContext } from 'react'
//import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '.././api/axios'
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

export const DailySalesTrends = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [totalSalesReport, setTotalSalesReport] = useState([])
  const [daySalesReport, setDaySalesReport] = useState([])
  const [totalInventoryReport, setTotalInventoryReport] = useState('')
  const [totalInventoryReportInitial, setTotalInventoryReportInitial] =
    useState('')
  const [totalProfitReport, setTotalProfitReport] = useState('')
  const [totalProfitReportDay, setTotalProfitReportDay] = useState('')

  const [costTotalBusinessValue, setCostTotalBusinessValue] = useState('')
  const [sellingPriceTotalBusinessValue, setSellingPriceTotalBusinessValue] =
    useState('')
  const [dailySalesTrends, setDailySalesTrends] = useState('')
  let [dailySalesTrendsLabels, setDailySalesTrendsLabels] = useState([])
  let [dailySalesTrendsData, setDailySalesTrendsData] = useState([])

  //const [dailySalesTrendsLabelsInitial, setDailySalesTrendsLabelsInitial] =
  useState([])
  //const [dailySalesTrendsDataInitial, setDailySalesTrendsDataInitial] =
  useState([])

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  var businessOwnerSelectedBranchID = localStorage.getItem(
    'businessOwnerSelectedBranchID'
  )
  businessOwnerSelectedBranchID = JSON.parse(businessOwnerSelectedBranchID)
  if (businessOwnerSelectedBranchID != '') {
    secondUserSystemSetupBranchID = businessOwnerSelectedBranchID
    secondUserPosition = 'Manager'
  }

  /*let [count, setCount] = useState(0)
  let counts = 0
  let countz = 0
  let delay = 3600
  setInterval(() => {
    setCount(count + 1)
  }, delay)
*/
  /*localStorage.setItem(
    'dailySalesTrendsDataGraph',
    JSON.stringify(dailySalesTrendsData)
  )
  localStorage.setItem(
    'dailySalesTrendsDataInitalGraph',
    JSON.stringify(dailySalesTrendsDataInitial)
  )*/
  /*if (
    JSON.stringify(dailySalesTrendsData) !=
    JSON.stringify(dailySalesTrendsDataInitial)
  ) {
    dailySalesTrendsData = dailySalesTrendsDataInitial
    //counts++
    //localStorage.setItem('dailySalesTrendsDataWordGraph', JSON.stringify('Yes'))
  } else {
    //if (dailySalesTrendsData != dailySalesTrendsDataInitial) {
    //counts++
    //localStorage.setItem('dailySalesTrendsDataWordGraph', JSON.stringify('No'))
    //alert(dailySalesTrendsData)
  }
  if (
    JSON.stringify(dailySalesTrendsLabels) !=
    JSON.stringify(dailySalesTrendsLabelsInitial)
  ) {
    dailySalesTrendsLabels = dailySalesTrendsLabelsInitial
  } else {
    //if (dailySalesTrendsData != dailySalesTrendsDataInitial) {
    //counts++
  }*/
  //}
  //alert(dailySalesTrendsDataInitial)
  //countz = counts
  /*useEffect(() => {
    var formDataSecondRowDataInitial = new FormData(this)
    formDataSecondRowDataInitial.append(
      'systemSetupID',
      secondUserSystemSetupID
    )
    formDataSecondRowDataInitial.append('reportType', 'secondRowData')
    formDataSecondRowDataInitial.append('dateToday', dateToday)
    formDataSecondRowDataInitial.append('monthToday', monthToday)
    formDataSecondRowDataInitial.append('userPosition', secondUserPosition)
    formDataSecondRowDataInitial.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    formDataSecondRowDataInitial.append('userID', secondUserID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataSecondRowDataInitial,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setDailySalesTrendsDataInitial(response.data)
      //alert(response.data[0])
    })
  }, [count])*/

  useEffect(() => {
    var formDataSecondRowData = new FormData(this)
    formDataSecondRowData.append('systemSetupID', secondUserSystemSetupID)
    formDataSecondRowData.append('reportType', 'secondRowData')
    formDataSecondRowData.append('dateToday', dateToday)
    formDataSecondRowData.append('monthToday', monthToday)
    formDataSecondRowData.append('userPosition', secondUserPosition)
    formDataSecondRowData.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    formDataSecondRowData.append('userID', secondUserID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataSecondRowData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      //alert(response.data)
      setDailySalesTrendsData(response.data)
      //setDailySalesTrendsDataInitial(response.data)
      //alert(response.data[0])
    })
  }, [secondUserSystemSetupBranchID])

  useEffect(() => {
    var formDataSecondRowLabels = new FormData(this)
    formDataSecondRowLabels.append('systemSetupID', secondUserSystemSetupID)
    formDataSecondRowLabels.append('reportType', 'secondRowLabels')
    formDataSecondRowLabels.append('dateToday', dateToday)
    formDataSecondRowLabels.append('monthToday', monthToday)
    formDataSecondRowLabels.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataSecondRowLabels,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setDailySalesTrendsLabels(response.data)
      //setDailySalesTrendsLabelsInitial(response.data)
      //alert(response.data[0])
    })
  }, [secondUserSystemSetupBranchID])

  /*useEffect(() => {
    var formDataSecondRowLabelsInitial = new FormData(this)
    formDataSecondRowLabelsInitial.append(
      'systemSetupID',
      secondUserSystemSetupID
    )
    formDataSecondRowLabelsInitial.append('reportType', 'secondRowLabels')
    formDataSecondRowLabelsInitial.append('dateToday', dateToday)
    formDataSecondRowLabelsInitial.append('monthToday', monthToday)
    formDataSecondRowLabelsInitial.append(
      'systemSetupBranchID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataSecondRowLabelsInitial,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      //setDailySalesTrendsLabels(response.data)
      setDailySalesTrendsLabelsInitial(response.data)
      //alert(response.data[0])
    })
  }, [count])*/

  const data = {
    labels: dailySalesTrendsLabels,
    datasets: [
      {
        label: 'SALES PER DAY',
        data: dailySalesTrendsData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, .3)',
        borderColor: '#02b844'
      }
    ]
  }

  return (
    <>
      <Bar data={data} />
    </>
  )
}

export default DailySalesTrends
