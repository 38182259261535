import { useRef, useState, useEffect, useContext } from 'react'
import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '../api/axios'
import $ from 'jquery'
import { NumericFormat  } from 'react-number-format';
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import Select from 'react-select'

export const StockTransfer = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [
    DBtableViewformDataTransferReport,
    setDBtableViewformDataTransferReport
  ] = useState([])

  const [posProductDropDown, setPosProductDropDown] = useState([])
  const [itemID, setItemID] = useState([{}])
  var [quantity, setQuantity] = useState('')
  //if (quantity === '') {
  //quantity = 0
  //}
  //alert(quantity)
  let [handleSubmitBtnStockTransfer, setHandleSubmitBtnStockTransfer] = useState('')
  const [scanner, setScanner] = useState('')
  const [quantityDB, setQuantityDB] = useState('')
  const [compareProductsPricesData, setCompareProductsPricesData] = useState([])
  const [compareProductsPricesLabel, setCompareProductsPricesLabel] = useState(
    []
  )
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [businessBranchNameTo, setBusinessBranchNameTo] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )
  const [businessBranchNameDropDownTo, setBusinessBranchNameDropDownTo] =
    useState([])

    let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
    
    useEffect(() => {
      if (scanner === '' || scanner === 'No' || scanner === undefined) {
        setBarcode('')
        setQuantity('')
        setServiceList([        
          {
            barcode: '',
        stockQuantity: ''
          }
        ])
      } else {
        setBarcode('')
        setQuantity('')
        setServiceList([        
          {
            barcode: '',
        stockQuantity: ''
          }
        ])
      }
      //alert(period)
    }, [scanner])

    

  const HandelChangeItemDropDown = obj => {
    var rodeen = []
    obj.map(val => {
      rodeen.push(val.value)
    })
    localStorage.setItem('ItemDropDownValue', JSON.stringify(rodeen))
    setItemID(JSON.stringify(rodeen))
  }

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  //alert('Branch ID = ' + secondUserSystemSetupBranchID)

  useEffect(() => {
   // alert(secondUserSystemSetupID)
    var formDataTransferReport = new FormData(this)
    formDataTransferReport.append('apiType', 'stockTransferReport')
    formDataTransferReport.append('system_setupID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataTransferReport,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setHandleSubmitBtn('')
      setDBtableViewformDataTransferReport(response.data)
      setTimeout(function () {
        $('#table').DataTable()
        addTableButtons()
      }, 1000)
      //alert(response.data)
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    setHandleSubmitBtn('Loading')
    var formDataBranchNames = new FormData(this)
    formDataBranchNames.append('apiType', 'getBusinessBranchNameDropDown')
    formDataBranchNames.append('businessNameID', secondUserSystemSetupID)
    formDataBranchNames.append(
      'businessBranchNameID',
      secondUserSystemSetupBranchID
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      setBusinessBranchNameDropDown(response.data)
      //alert(response.data)
    })
    //.catch(err => alert('ERROR : Kindly Contact Your Administrator'))
  }, [])

  useEffect(() => {
    var formDataBranchNames = new FormData(this)
    formDataBranchNames.append('apiType', 'getBusinessBranchNameDropDown')
    formDataBranchNames.append('businessNameID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDownTo(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getPosProductDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataProductNames = new FormData(this)
    formDataProductNames.append('apiType', 'getPosProductDropDown')
    formDataProductNames.append('businessNameID', secondUserSystemSetupID)
    formDataProductNames.append('branchNameID', businessBranchName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataProductNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      const posProductDropDown = response.data
      // alert(response.data)
      setPosProductDropDown(
        posProductDropDown.map(
          ({ id, product, stockID, stock_quantity, product_unit_price }) => ({
            id,
            label:
              product +
              '(Quantity = ' +
              stock_quantity +
              ') (Unit Price = K ' +
              product_unit_price +
              ')',
            value: stockID
          })
        )
      )
      //setPosProductDropDown(countriesLocal)
      var a = response.data
      localStorage.setItem('arrHome', JSON.stringify(a))
      //a = JSON.parse(localStorage.getItem('arr')) || [];
      //alert(a);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [posProductDropDown, businessBranchName])

  useEffect(() => {
    //alert(itemID)
    var formDataQuantityDB = new FormData(this)
    formDataQuantityDB.append('itemID', itemID)
    formDataQuantityDB.append('apiType', 'transferStockQuantityDB')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataQuantityDB,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setQuantityDB(response.data)
      //alert(response.data)
    })
    //.catch(err => alert(err))
  }, [itemID])

  useEffect(() => {
    var formDataCompareProductsPriceslabel = new FormData(this)
    formDataCompareProductsPriceslabel.append('itemID', itemID)
    formDataCompareProductsPriceslabel.append(
      'reportType',
      'compareProductsPricesLabel'
    )
    //formDataCompareProductsPriceslabel.append('fromDate', fromDate)
    //formDataCompareProductsPriceslabel.append('toDate', toDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataCompareProductsPriceslabel,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setCompareProductsPricesLabel(response.data)
    })
  }, [businessBranchName, itemID])

  const compareProductsPrices = {
    labels: compareProductsPricesLabel,
    datasets: [
      {
        label: 'PRODUCT SALES',
        data: compareProductsPricesData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, .3)',
        borderColor: '#02b844'
      }
    ]
  }

  const handleSubmit = async e => {
    //alert(quantity)
    //e.preventDefault()
    //useEffect(() => {
      setHandleSubmitBtnStockTransfer('Loading')
    var userType = ''
    if (secondUserSystemSetupBranchID === '') {
      userType = 'Owner'
    } else if (secondUserSystemSetupBranchID != '') {
      userType = 'User'
    }
    var formDatastockTransferUpload = new FormData(this)
    formDatastockTransferUpload.append('itemID', itemID)
    formDatastockTransferUpload.append('apiType', 'stockTransferUpload')
    formDatastockTransferUpload.append('quantity', quantity)
    formDatastockTransferUpload.append('quantityDB', quantityDB)
    formDatastockTransferUpload.append('userID', secondUserID)
    formDatastockTransferUpload.append('userType', userType)
    formDatastockTransferUpload.append('scanner', scanner)
    formDatastockTransferUpload.append('stockQuantity', stockQuantity)
    formDatastockTransferUpload.append('barcode', barcode)
    formDatastockTransferUpload.append('businessBranchName', businessBranchName)
    formDatastockTransferUpload.append(
      'businessBranchNameTo',
      businessBranchNameTo
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDatastockTransferUpload,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success   
      setHandleSubmitBtnStockTransfer('')   
      setQuantity('')
      setBusinessBranchName('')
      setBusinessBranchNameTo('')
      setScanner('')
      setServiceList([        
        {
          barcode: '',
          stockQuantity: ''
        }
      ])
      alert(response.data)
    })
  }
  //}, [businessBranchName, itemID])*/

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable()
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    var table = $('#table').DataTable()

    new $.fn.dataTable.Buttons(table, {
      buttons: [
        {
          extend: 'copy',
          text: 'Copy <i class="fa fa-files-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        },
        {
          extend: 'excel',
          text: 'Excel <i class="fa fa-file-excel-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        },
        //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
        {
          extend: 'pdf',
          text: 'PDF <i class="fa fa-file-pdf-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        },
        {
          extend: 'print',
          text: 'Print <i class="fa fa-print"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        }
      ]
    })

    table.buttons(0, null).containers().appendTo('#filterTopCriteria')
  }

  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)


  var barcodeArr = []
  var stockQuantityArr = []
  const [serviceList, setServiceList] = useState([
    {
      barcode: '',
      stockQuantity: ''
    }
  ])

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        barcode: '',
        stockQuantity: ''
      }
    ])
  }
  const handleServiceRemove = index => {
    const list = [...serviceList]
    list.splice(index, 1)
    setServiceList(list)

    list.map(val => {
      barcodeArr.push(val.barcode)
      stockQuantityArr.push(val.stockQuantity)
    })
  }
  const handleChange = (e, index) => {
    var { name, value } = e.target
    const list = [...serviceList]
    list[index][name] = value
    setServiceList(list)

    list.map(val => {
      barcodeArr.push(val.barcode)
      stockQuantityArr.push(val.stockQuantity)
    })    

    setBarcode(JSON.stringify(barcodeArr))
    setStockQuantity(JSON.stringify(stockQuantityArr))
    //alert('Barcode Value ='+barcode);
    
  }

  const [barcode, setBarcode] = useState([{}])
  const [stockQuantity, setStockQuantity] = useState([{}])

var disableBranchDrop = 'Yes';
//useEffect(() => {
if(parseInt(quantityDB) < 1 )
{
  disableBranchDrop = 'Yes';
}
else if(parseInt(quantityDB) > 0 && parseInt(quantity) > 0 && scanner != 'Yes'){
    disableBranchDrop = 'No';
}
else if(scanner === 'Yes' && parseInt(quantityDB) > 0 ){
  disableBranchDrop = 'No';
  //alert('quantityDB = '+quantityDB+ ' quantity = '+quantity)
}
if(parseInt(quantityDB) < parseInt(quantity)){
  //alert("Am here")
  disableBranchDrop = 'Yes';
}
//}, [quantity, businessBranchName])

  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Transfer Stock From One Branch To
        Another - {systemSetupBranchIDStoreName}
      </title>
      <>
        <Headers />
      </>
      <title>Stock Transfer</title>
      {/*<!-- Preloader -->*/}
      <div hidden={handleSubmitBtn === ''}>
      <div id="overlayer"></div>
      <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    </div>
      <div>
        <body class='col-12'>
          {/* Main layout */}
          <main>
            <div class='container-fluid'>
              <section class='section mt-5'>
                {/*Grid row */}
                <div class='row'>
                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <h6 class='form-header white-text ocean blue-gradient'>
                          STOCK TRANSFER
                        </h6>
                        {/*<DailyTotalProfits />*/}
                        {/*<Line data={dataProfits} />*/}
                        <div class='row'>
                        <div class='col-lg-1 col-md-12 mb-1 text-left'>
                          <div class='black-text'>
                            <label>
                              Barcode <i class='red-text'></i>{' '}
                            </label>
                            <select
                              class='custom-select d-block w-100'
                              autoComplete='off'
                              onChange={e => setScanner(e.target.value)}
                              value={scanner}                              
                            >
                              <option value=''>.....</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>

                          <div class='col-md-3 col-12 text-left pl-4'>
                            <label>From</label>
                            <select
                              class='custom-select d-block w-100'
                              id='businessBranchName'
                              onChange={e =>
                                setBusinessBranchName(e.target.value)
                              }
                              autoComplete='off'
                              value={businessBranchName}
                            >
                              <option value=''>
                                ...Business Branch Name .........
                              </option>
                              {businessBranchNameDropDown.map(val => {
                                return (
                                  <option value={val.system_setup_branchID}>
                                    {val.branch_name}{' '}
                                  </option>
                                )
                              })}
                            </select>{' '}
                          </div>

                          <div class='col-md-4 col-12 text-left pl-4'>
                            <label>Choose Product Name</label>
                            <Select
                              class='form-control'
                              options={posProductDropDown}
                              //isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                              required
                              //value={itemID} // this doesn't let me click options
                              //maxMenuHeight={150}
                              onChange={option =>
                                HandelChangeItemDropDown(option)
                              } // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                          <div class='col-md-1 col-12 text-left pl-1'>
                            <div class=''>
                              <label>Quantity</label>
                              <br />
                              <input
                                type='text'
                                class='form-control'
                                onChange={e => setQuantity(e.target.value)}
                                value={quantity}
                                disabled={scanner === 'Yes'}
                              />
                            </div>
                          </div>
                          <div class='col-md-3 col-12 text-left pl-4'>
                            <label>To</label>
                            <select
                              class='custom-select d-block w-100'
                              id='businessBranchName'
                              onChange={e =>
                                setBusinessBranchNameTo(e.target.value)
                              }
                              autoComplete='off'
                              value={businessBranchNameTo}
                              disabled={
                                disableBranchDrop === 'Yes'
                              }
                            >
                              <option value=''>
                                ...Business Branch Name .........
                              </option>
                              {businessBranchNameDropDownTo.map(val => {
                                return (
                                  <option value={val.system_setup_branchID}>
                                    {val.branch_name}{' '}
                                  </option>
                                )
                              })}
                            </select>{' '}
                          </div>
                          </div>

                          
                  {/*<!--Grid row--> */}
                  {serviceList.map((singleServiceList, index) => {
                    return (
                      <>
                        <div
                          class='row'
                          key={index}
                          hidden={
                            scanner === '' ||
                            scanner === 'No' ||
                            scanner === undefined
                          }
                        >
                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                {index + 1} .<span class='red-text'></span>
                              </label>
                              <br /> <br />
                            </div>
                          </div>

                          <div class='col-md-4 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span>
                                  Barcode
                                </span>
                                <span class='red-text'></span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.barcode}
                                onChange={e => handleChange(e, index)}
                                id='barcode'
                                name='barcode'
                              />
                            </div>
                          </div>

                          <div class='col-md-4 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span>
                                  Stock Quantity
                                </span>
                                <span class='red-text'></span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.stockQuantity}
                                onChange={e => handleChange(e, index)}
                                id='stockQuantity'
                                name='stockQuantity'
                              />
                            </div>
                          </div>

                          {serviceList.length > 1 && (
                            <div class='col-md-2 mb-1'>
                              <div class='md-form'>
                                <br /> <br /> <br />
                                <input
                                  type='button'
                                  class='form-control btn white-text red accent-2'
                                  value='X Remove item Barcode'
                                  onClick={handleServiceRemove}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          class='row'
                          hidden={
                            scanner === '' ||
                            scanner === 'No' ||
                            scanner === undefined
                          }
                        >
                          {serviceList.length - 1 === index && (
                            <div class='col-md-12'>
                              <div class='md-form'>
                                <button
                                  class='btn white-text blue accent-2 col-md-12'
                                  onClick={() => handleServiceAdd(index)}
                                >
                                  + Add Another Item Barcode
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  })}
                  {/*<!--\Grid row--> */}

                  <div class='row'>
                          <div class='col-md-12 col-12 text-left'>
                            <label></label>
                            <button                              
                              class='form-control bg-primary mt-3 white-text'
                              disabled={
                                scanner !='Yes' &&
                                (handleSubmitBtnStockTransfer === 'Loading' ||
                                  quantity === '' ||
                                parseInt(quantity) < 1 ||
                                quantityDB < 1 ||
                                businessBranchNameTo === '' ||
                                businessBranchName === '' ||
                                itemID === '' ||
                                parseInt(quantity) > parseInt(quantityDB) ||
                                businessBranchNameTo === businessBranchName 
                                ) ||
                                scanner ==='Yes' && (handleSubmitBtnStockTransfer === 'Loading' ||
                                  quantityDB < 1 ||
                                businessBranchNameTo === '' ||
                                businessBranchName === '' ||
                                itemID === '' ||
                                parseInt(quantity) > parseInt(quantityDB) ||
                                businessBranchNameTo === businessBranchName )
                              }
                              onClick={handleSubmit}
                            >
                                   <div class="spinner-border" role="status" hidden = {handleSubmitBtnStockTransfer === ''}>
          </div>
                              Transfer
                              </button>
                          </div>
                        </div>

                        <hr />

                        <center>
                          <div id='filterTopCriteria'></div>
                        </center>
                        <table
                          id='table'
                          class='table table-striped table-bordered table-hover table-sm'
                        >
                          <thead class='form-header text-uppercase ocean blue-gradient'>
                            {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                            <tr>
                              <th>S/N</th>
                              <th>From</th>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th>To</th>
                              <th>Name</th>
                              <th>Date</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {DBtableViewformDataTransferReport.map(
                              (val, index) => {
                                return (
                                  <tr>
                                    {/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
                                    <td>{index + 1}</td>
                                    <td>{val.from}</td>
                                    <td>{val.product}</td>
                                    <td>
                                    <NumericFormat value={val.quantity} displayType={'text'} thousandSeparator={true} prefix={''} />
                                    </td>
                                    <td>{val.to}</td>
                                    <td>{val.user}</td>
                                    <td>{val.date}</td>
                                    <td>{val.time}</td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default StockTransfer
