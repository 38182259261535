import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
/*import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'*/
import $ from 'jquery'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';

const ViewUsers = () => {
  let navigate = useNavigate()

  const handleRegisterUsers = async e => {
    navigate('/RegisterUsers')
  }

  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewUsers, setDBtableViewUsers] = useState([])
  const [DBtableViewOneUser, setDBtableViewOneUser] = useState([])

  const [userRole, setUserRole] = useState('')
  const [userPosition, setUserPosition] = useState('')
  const [userBranch, setUserBranch] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [update, setUpdate] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  const secondUserPosition = JSON.parse(firstUserPosition)
  //alert(systemSetupIDInitial)
  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getBusinessBranchNameDropDown`, {
      businessNameID: businessName
    })*/
    var formDataViewBusinessBranchNames = new FormData(this)
    formDataViewBusinessBranchNames.append(
      'apiType',
      'getBusinessBranchNameDropDown'
    )
    formDataViewBusinessBranchNames.append(
      'businessNameID',
      systemSetupIDInitial
    )
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataViewBusinessBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDown(response.data)
    })
    //.catch(err => alert('ERROR : Kindly Contact Your Administrator'))
  }, [])

  const handleSubmitUpdate = async e => {
    //alert(update)
    //e.preventDefault()

    var formData = new FormData(this)
    formData.append('apiType', 'getViewUsers')
    formData.append('usersIDToUpdate', update)
    formData.append('userRole', userRole)
    formData.append('userPosition', userPosition)
    formData.append('userBranch', userBranch)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        alert(response.data)
      })
      .catch(function (response) {
        //handle error
        //alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  //alert("id = "+systemSetupIDInitial);
  useEffect(() => {
  /*Axios.post(`${server_app_api}/api/getViewUsers`, {
      businessNameID: systemSetupIDInitial
    })*/

    setHandleSubmitBtn('Loading')
  var formDataViewUsers = new FormData(this)
  formDataViewUsers.append('apiType', 'getViewUsers')
  formDataViewUsers.append('businessNameID', systemSetupIDInitial)
  //Axios.get(`${server_app_api}/api/getBusinessCategoryDropDown`).then(
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/index.php`,
    //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/registerBusiness.php',
    data: formDataViewUsers,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    setHandleSubmitBtn('')
    setDBtableViewUsers(response.data)

    setTimeout(function () {
      $('#table').DataTable()
      addTableButtons()
    }, 3000)
  }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const UpdateRecord = usersID => {
    var formDataGetOneUser = new FormData(this)
    formDataGetOneUser.append('apiType', 'getViewUsers')
    formDataGetOneUser.append('usersID', usersID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataGetOneUser,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setDBtableViewOneUser(response.data)
    })
    setUpdate(usersID)

    //alert('Update Value = ' + stockID)
  }

  const deleteRecord = userID => {
    var formDataDeleteOneUser = new FormData(this)
    formDataDeleteOneUser.append('apiType', 'getViewUsers')
    formDataDeleteOneUser.append('usersIDToDelete', userID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataDeleteOneUser,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      alert(response.data)
    })
  }

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable()
      addTableButtons()
    }, 10000)
  })*/

  function addTableButtons () {
    var table = $('#table').DataTable()

    new $.fn.dataTable.Buttons(table, {
      buttons: [
        {
          extend: 'copy',
          text: 'Copy <i class="fa fa-files-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        },
        {
          extend: 'excel',
          text: 'Excel <i class="fa fa-file-excel-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        },
        //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
        {
          extend: 'pdf',
          text: 'PDF <i class="fa fa-file-pdf-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        },
        {
          extend: 'print',
          text: 'Print <i class="fa fa-print"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6] }
        }
      ]
    })

    table.buttons(0, null).containers().appendTo('#filterTopCriteria')
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - View Business Users / Employees -{' '}
          {systemSetupBranchIDStoreName}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader -->*/}
        <div hidden={handleSubmitBtn ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
          <span class='visually-hidden'>Loading...</span>
        </div>
      </div>
      </div>

        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container col-12'>
              <div class='card mt-5'>
                <div class='card-body'>
                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      REGISTERED USERS{' '}
                      <span style={{ float: 'right' }}>
                        {' '}
                        {secondUserPosition === 'Business Owner' ? (
                          <>
                            <a onClick={handleRegisterUsers}>
                              {' '}
                              <i class='w-fa fa fa-plus'></i>Add Users
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                    </h6>

                    {/*<!-- Modal: modalManagerLogin  -->*/}
                    <div
                      class='modal fade col-lg-12'
                      id='modalUpdateUser'
                      tabindex='-1'
                      role='dialog'
                      aria-labelledby='myModalLabel'
                      aria-hidden='true'
                    >
                     <MDBModalDialog size='xl'>
                        {/*<!-- Content -->*/}
                        <div class='modal-content'>
                          {/* <!-- Header -->*/}
                          <div class='modal-header ocean blue-gradient white-text '>
                            <h4 class=''>Edit Users :</h4>
                            <button
                              type='button'
                              class='close waves-effect waves-light white-text'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
                          </div>
                          {/*<!-- Body -->*/}
                          <div class='modal-body mb-0 '>
                            {DBtableViewOneUser.map(valOneUser => {
                              return (
                                <>
                                  <table class='' style={{ width: '100%' }}>
                                    <tr>
                                      <th>OLD</th> <th>NEW</th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Unit Role <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              value={valOneUser.role}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>{' '}
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              user Role <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br /> <br />
                                            <select
                                              class='custom-select d-block w-100'
                                              searchable='Search here..'
                                              onChange={e =>
                                                setUserRole(e.target.value)
                                              }
                                            >
                                              <option value=''>
                                                Choose...
                                              </option>
                                              <option>User</option>
                                              <option>
                                                System Administrator
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              User Position{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              value={valOneUser.position}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>{' '}
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              User Position{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <br />
                                            <select
                                              class='custom-select d-block w-100'
                                              searchable='Search here..'
                                              onChange={e =>
                                                setUserPosition(e.target.value)
                                              }
                                            >
                                              <option value=''>
                                                Choose...
                                              </option>
                                              <option>Cashier</option>
                                              <option>Manager</option>
                                              <option>Auditor</option>
                                            </select>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              User branch / Outlet{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              value={valOneUser.branch_name}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>{' '}
                                      <td>
                                        <div class='col-lg-12 col-md-12 mb-4'>
                                          <label>
                                            User branch / Outlet{' '}
                                            <i class='red-text'></i>{' '}
                                          </label>{' '}
                                          <br />
                                          <select
                                            class='custom-select d-block w-100'
                                            searchable='Search here..'
                                            onChange={e =>
                                              setUserBranch(e.target.value)
                                            }
                                          >
                                            <option value=''>Choose...</option>
                                            {businessBranchNameDropDown.map(
                                              val => {
                                                return (
                                                  <option
                                                    value={
                                                      val.system_setup_branchID
                                                    }
                                                  >
                                                    {val.branch_name}{' '}
                                                  </option>
                                                )
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </td>
                                    </tr>

                                    {/*End Of Row 3*/}
                                    <tr>
                                      {' '}
                                      <td colspan='2'>
                                        <button
                                          class='btn btn-primary'
                                          onClick={handleSubmitUpdate}
                                          disabled={
                                            userRole === '' &&
                                            userPosition === '' &&
                                            userBranch === ''
                                          }
                                        >
                                          Update
                                        </button>
                                      </td>
                                    </tr>
                                  </table>
                                </>
                              )
                            })}
                          </div>
                          {/*Modal Body Ends Here*/}
                        </div>
                        </MDBModalDialog>
                      {/*<!-- Content -->*/}
                    </div>
                    {/* <!-- Modal: modalManager .-->*/}

                    <center>
                      <div id='filterTopCriteria'></div>
                    </center>
                    <table
                      id='table'
                      class='table table-striped table-bordered table-hover table-sm'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        <tr>
                          <th>S/N</th>
                          <th>Branch</th>
                          <th>Name</th>
                          <th>email</th>
                          <th>phone</th>
                          <th>position</th>
                          <th>role</th>
                          {secondUserPosition === 'Business Owner' ? (
                            <>
                              <th>UPDATE</th>
                              <th>DELETE</th>
                            </>
                          ) : (
                            <></>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {DBtableViewUsers.map((val, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{val.branch_name}</td>
                              <td>{val.Last_name + ' ' + val.first_name}</td>
                              <td>{val.email}</td>
                              <td>{val.phone}</td>
                              <td>{val.position}</td>
                              <td>{val.role}</td>

                              {secondUserPosition === 'Business Owner' ? (
                                <>
                                  <td>
                                    {' '}
                                    <span
                                      onClick={() => {
                                        UpdateRecord(val.usersID)
                                      }}
                                      type='button'
                                      class='fa fa-edit blue-text'
                                      title='Update This Record'
                                      data-toggle='modal'
                                      data-target='#modalUpdateUser'
                                    ></span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        deleteRecord(val.usersID)
                                      }}
                                      type='button'
                                      class='fa fa-trash red-text'
                                      title='Delete This Record From The Database'
                                    ></span>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Data Table View Ends Here*/}
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default ViewUsers
