import { useRef, useState, useEffect, useContext } from 'react'
import Headers from './Headers'
import { useNavigate, redirect } from 'react-router-dom'
import Axios from '.././api/axios'
import { MDBContainer } from 'mdbreact'
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter
} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import Select from 'react-select'

export const CompareProductSales = () => {
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const [posProductDropDown, setPosProductDropDown] = useState([])
  const [itemID, setItemID] = useState([{}])
  const [toDate, setToDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [compareProductsPricesData, setCompareProductsPricesData] = useState([])
  const [compareProductsPricesLabel, setCompareProductsPricesLabel] = useState(
    []
  )
  const [businessBranchName, setBusinessBranchName] = useState('')
  const [businessBranchNameDropDown, setBusinessBranchNameDropDown] = useState(
    []
  )

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  if (loggedIn === 'No' || loggedIn ==='') {
    navigate('/Logout')
  }
  
  const HandelChangeItemDropDown = obj => {
    var rodeen = []
    obj.map(val => {
      rodeen.push(val.value)
    })
    localStorage.setItem('ItemDropDownValue', JSON.stringify(rodeen))
    setItemID(JSON.stringify(rodeen))
  }

  let navigate = useNavigate()
  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )

  const currentDate = new Date()
  const dateToday = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const monthToday = `${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`

  const firstUserSystemSetupID = localStorage.getItem('systemSetupID')
  let secondUserSystemSetupID = JSON.parse(firstUserSystemSetupID)

  const firstUserRole = localStorage.getItem('userRole')
  let secondUserRole = JSON.parse(firstUserRole)

  const firstUserPosition = localStorage.getItem('userPosition')
  let secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserID = localStorage.getItem('userID')
  let secondUserID = JSON.parse(firstUserID)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let secondUserSystemSetupBranchID = JSON.parse(firstUserSystemSetupBranchID)

  //alert('Branch ID = ' + secondUserSystemSetupBranchID)

  useEffect(() => {
    var formDataBranchNames = new FormData(this)
    formDataBranchNames.append('apiType', 'getBusinessBranchNameDropDown')
    formDataBranchNames.append('businessNameID', secondUserSystemSetupID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataBranchNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setBusinessBranchNameDropDown(response.data)
    }) //.catch((err) => alert("ERROR : Kindly Contact Your Administrator"));
  }, [])

  useEffect(() => {
    /*Axios.post(`${server_app_api}/api/getPosProductDropDown`, {
      businessNameID: systemSetupIDInitial
    })*/
    var formDataProductNames = new FormData(this)
    formDataProductNames.append('apiType', 'getPosProductDropDown')
    formDataProductNames.append('businessNameID', secondUserSystemSetupID)
    formDataProductNames.append('branchNameID', businessBranchName)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataProductNames,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      const posProductDropDown = response.data
      // alert(response.data)
      setPosProductDropDown(
        posProductDropDown.map(
          ({ id, product, stockID, stock_quantity, product_unit_price }) => ({
            id,
            label:
              product +
              '(Quantity = ' +
              stock_quantity +
              ') (Unit Price = K ' +
              product_unit_price +
              ')',
            value: stockID
          })
        )
      )
      //setPosProductDropDown(countriesLocal)
      var a = response.data
      localStorage.setItem('arrHome', JSON.stringify(a))
      //a = JSON.parse(localStorage.getItem('arr')) || [];
      //alert(a);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [posProductDropDown, businessBranchName])

  useEffect(() => {
    var formDataCompareProductsPricesData = new FormData(this)
    formDataCompareProductsPricesData.append('itemID', itemID)
    formDataCompareProductsPricesData.append(
      'reportType',
      'compareProductsPricesData'
    )
    formDataCompareProductsPricesData.append('fromDate', fromDate)
    formDataCompareProductsPricesData.append('toDate', toDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataCompareProductsPricesData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setCompareProductsPricesData(response.data)
    })
  }, [businessBranchName, itemID, fromDate, toDate])

  useEffect(() => {
    var formDataCompareProductsPriceslabel = new FormData(this)
    formDataCompareProductsPriceslabel.append('itemID', itemID)
    formDataCompareProductsPriceslabel.append(
      'reportType',
      'compareProductsPricesLabel'
    )
    formDataCompareProductsPriceslabel.append('fromDate', fromDate)
    formDataCompareProductsPriceslabel.append('toDate', toDate)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/homeSummarReports.php`,
      //url: 'http://localhost:8080/react/kanchy/src/server/reactphp/homeSummarReports.php',
      data: formDataCompareProductsPriceslabel,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) {
      //handle success
      setCompareProductsPricesLabel(response.data)
    })
  }, [businessBranchName, itemID, fromDate, toDate])

  const compareProductsPrices = {
    labels: compareProductsPricesLabel,
    datasets: [
      {
        label: 'PRODUCT SALES',
        data: compareProductsPricesData,
        fill: true,
        backgroundColor: 'rgba(6, 156,51, .3)',
        borderColor: '#02b844'
      }
    ]
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)
  return (
    <>
      <title>
        KANCH INNOVATIVE TECHNOLOGIES - Compare Product Sales -{' '}
        {systemSetupBranchIDStoreName}{' '}
      </title>
      <>
        <Headers />
      </>

      {/*<!-- Preloader -->
      <div id="overlayer"></div>
    <div class="loader">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> */}

      <div>
        <body class='col-12'>
          {/* Main layout */}
          <main>
            <div class='container-fluid'>
              <section class='section mt-5'>
                {/*Grid row */}
                <div class='row'>
                  <div class='col-lg-12 col-md-12'>
                    <div class='card mb-4'>
                      <div class='card-body'>
                        <h6 class='form-header white-text ocean blue-gradient'>
                          COMPARE PRODUCT SALES
                        </h6>
                        {/*<DailyTotalProfits />*/}
                        {/*<Line data={dataProfits} />*/}
                        <div class='row'>
                          <div class='col-md-3 col-12 text-left pl-4'>
                            <label>Choose Outlet / Branch</label>
                            <select
                              class='custom-select d-block w-100'
                              id='businessBranchName'
                              onChange={e =>
                                setBusinessBranchName(e.target.value)
                              }
                              autoComplete='off'
                            >
                              <option value=''>
                                ...Business Branch Name .........
                              </option>
                              {businessBranchNameDropDown.map(val => {
                                return (
                                  <option value={val.system_setup_branchID}>
                                    {val.branch_name}{' '}
                                  </option>
                                )
                              })}
                            </select>{' '}
                          </div>

                          <div class='col-md-5 col-12 text-left pl-4'>
                            <label>Choose Products</label>
                            <Select
                              class='form-control'
                              options={posProductDropDown}
                              //isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                              required
                              //value={itemID} // this doesn't let me click options
                              //maxMenuHeight={150}
                              onChange={option =>
                                HandelChangeItemDropDown(option)
                              } // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                          <div class='col-md-2 col-12 text-left pl-4'>
                            <label>From Date</label>
                            <input
                              type='date'
                              class='form-control'
                              value={fromDate}
                              onChange={e => setFromDate(e.target.value)}
                            />
                          </div>
                          <div class='col-md-2 col-12 text-left pl-4'>
                            <label>To Date</label>
                            <input
                              type='date'
                              class='form-control'
                              value={toDate}
                              onChange={e => setToDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div
                          class='row col-12'
                          hidden={posProductDropDown === ''}
                        >
                          <Bar data={compareProductsPrices} />
                          {/*<compareProductsPrices />*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </section>
            </div>
          </main>
          {/* Main layout */}
        </body>
      </div>
    </>
  )
}

export default CompareProductSales
