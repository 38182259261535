import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Headers from './Headers'
import { NumericFormat  } from 'react-number-format';
import '../App.css'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from '../api/axios'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import $ from 'jquery'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';

const ViewStock = () => {
  let navigate = useNavigate()
  const handleRegisterStock = async e => {
    navigate('/RegisterStock')
  }

  const firstUserLoggedInSessionExpired = localStorage.getItem('loggedIn')
  let secondUserLoggedInSessionExpired = JSON.parse(
    firstUserLoggedInSessionExpired
  )
  useEffect(() => {
    if (secondUserLoggedInSessionExpired === 'No') {
      navigate('/Logout')
    }
  }, [])
  const server_app_api = window.SERVER_APP_API
  const server_app_api_php = window.SERVER_APP_API_PHP

  const userRef = useRef()
  const errRef = useRef()

  const [DBtableViewStock, setDBtableViewStock] = useState([])
  const [DBtableViewOneStock, setDBtableViewOneStock] = useState([])

  const [stockQuantity, setStockQuantity] = useState('')
  const [stockUnitCost, setStockUnitCost] = useState('')
  const [unitPrice, setUnitPrice] = useState('')

  const [update, setUpdate] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  var [barcodeStockID, setBarcodeStockID] = useState('')
  let [handleSubmitBtnUpdateBarcode, setHandleSubmitBtnUpdateBarcode] = useState('')
  let [handleSubmitBtnUpdate, setHandleSubmitBtnUpdate] = useState('')
  let [handleSubmitBtnDelete, setHandleSubmitBtnDelete] = useState('')

  const firstSystemSetupID = localStorage.getItem('systemSetupID')
  const systemSetupIDInitial = JSON.parse(firstSystemSetupID)

  const firstUserPosition = localStorage.getItem('userPosition')
  const secondUserPosition = JSON.parse(firstUserPosition)

  const firstUserSystemSetupBranchID = localStorage.getItem(
    'systemSetupBranchID'
  )
  let businessBranchName = JSON.parse(firstUserSystemSetupBranchID)

  const handleSubmitUpdate = async e => {
    //alert(update)
    //e.preventDefault()
    setHandleSubmitBtnUpdate('Loading')
    var formData = new FormData(this)
    formData.append('apiType', 'getViewStock')
    formData.append('stockIDToUpdate', update)
    formData.append('stockQuantity', stockQuantity)
    formData.append('stockUnitCost', stockUnitCost)
    formData.append('unitPrice', unitPrice)

    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        setHandleSubmitBtnUpdate('')
        setStockQuantity('')
        setStockUnitCost('')
        setUnitPrice('')
        alert(response.data)
        // setDBtableViewOneStock(response.data)
      })
      .catch(function (response) {
        //handle error
        //alert('ERROR : Kindly Contact Your Adminitrator')
      })
  }

  useEffect(() => {
  /*Axios.post(`${server_app_api}/api/getViewStock`, {
      businessNameID: systemSetupIDInitial
    })*/

  setHandleSubmitBtn('Loading')
  var formDataStock = new FormData(this)
  formDataStock.append('apiType', 'getViewStock')
  formDataStock.append('businessNameID', systemSetupIDInitial)
  formDataStock.append('businessBranchName', businessBranchName)
  formDataStock.append('position', secondUserPosition)
  Axios({
    method: 'post',
    url: `${server_app_api_php}/reactphp/index.php`,
    data: formDataStock,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    setHandleSubmitBtn('')
    setDBtableViewStock(response.data)

    setTimeout(function () {
      $('#table').DataTable({
        fnFooterCallback: function (nRow, aasData, iStart, iEnd, aiDisplay) {
          var columnas = [3, 4, 5, 6] //the columns you wish to add
          for (var j in columnas) {
            var columnaActual = columnas[j]
            var total = 0
            for (var i = iStart; i < iEnd; i++) {
              total = total + parseFloat(aasData[aiDisplay[i]][columnaActual])
            }
            $($(nRow).children().get(columnaActual)).html(total)
          } // end of for loop
        }, // end of fnFootCallback function
        retrieve: true, // this hides the errors
        pageLength: 10,
        lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
      
      })
      addTableButtons()
    }, 1000)
  }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  const UpdateBarcode = stockID => {
    setBarcodeStockID(stockID)
  }
  const handleUpdateStockBarcode = async e => {
    setHandleSubmitBtnUpdateBarcode('Loading')
    var formData = new FormData(this)
    formData.append('apiType', 'postUpdateStockBarcode')
    formData.append('barcode', barcode)
    formData.append('stockQuantityBarcode', stockQuantityBarcode)
    formData.append('stockID', barcodeStockID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtnUpdateBarcode('')
      setServiceList([        
        {
          barcode: '',
          stockQuantityBarcode: ''
        }
      ])
      alert(response.data)
    })
  }

  const UpdateRecord = stockID => {
    
    var formDataGetOneStock = new FormData(this)
    formDataGetOneStock.append('apiType', 'getViewStock')
    formDataGetOneStock.append('stockID', stockID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataGetOneStock,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      //setHandleSubmitBtnUpdate('')
      setDBtableViewOneStock(response.data)
    })
    setUpdate(stockID)

    //alert('Update Value = ' + stockID)
  }

  const deleteRecord = stockID => {
    setHandleSubmitBtnDelete('Loading')
    var formDataDeleteOneStock = new FormData(this)
    formDataDeleteOneStock.append('apiType', 'getViewStock')
    formDataDeleteOneStock.append('stockIDToDelete', stockID)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/index.php`,
      data: formDataDeleteOneStock,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtnDelete('')
      alert(response.data)
    })
  }

  /*if (!$.fn.DataTable.isDataTable("#myTable")) {
            $(document).ready(function () {
              setTimeout(function () {
                $("#table").dataTable().fnDestroy();
                $("#table").DataTable({
                  pagingType: "full_numbers",
                  pageLength: 5,
                  processing: true,
                  dom: "fBrtip",
                  select: {
                    style: "single",
                  },
                  
                  buttons: [
                    {
                      extend: "pageLength",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "copy",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "csv",
                      className: "btn btn-primary bg-info",
                    },
                    {
                      extend: "print",
                      customize: function (win) {
                        $(win.document.body).css("font-size", "10pt");
                        $(win.document.body)
                          .find("table")
                          .addClass("compact")
                          .css("font-size", "inherit");
                      },
                      className: "btn btn-primary bg-info",
                    },
					
                  ],
      
                  fnRowCallback: function (
                    nRow,
                    aData,
                    iDisplayIndex,
                    iDisplayIndexFull
                  ) {
                    var index = iDisplayIndexFull + 1;
                    $("td:first", nRow).html(index);
                    return nRow;
                  },
      
                  lengthMenu: [
                    [5, 10, 50, 100, 500, 1000, 5000, 10000, -1],
                    [5, 10, 50, 100, 500, 1000, 5000, 10000, "All"],
                  ],
                  columnDefs: [
                    {
                      targets: 0,
                      render: function (data, type, row, meta) {
                        return type === "export" ? meta.row + 1 : data;
                      },
                    },
                  ],
                });
              }, 1);
            });
          }*/
  /*;(function () {
    var preloader = function () {
      var loader = document.querySelector('.loader')
      var overlay = document.getElementById('overlayer')

      function fadeOut (el) {
        el.style.opacity = 1
        ;(function fade () {
          if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none'
          } else {
            requestAnimationFrame(fade)
          }
        })()
      }

      setTimeout(function () {
        fadeOut(loader)
        fadeOut(overlay)
      }, 200)
    }
    preloader()
  })()*/

  //initialize datatable
  /*$(function () {
    setTimeout(function () {
      $('#table').DataTable({
        fnFooterCallback: function (nRow, aasData, iStart, iEnd, aiDisplay) {
          var columnas = [3, 4, 5, 6] //the columns you wish to add
          for (var j in columnas) {
            var columnaActual = columnas[j]
            var total = 0
            for (var i = iStart; i < iEnd; i++) {
              total = total + parseFloat(aasData[aiDisplay[i]][columnaActual])
            }
            $($(nRow).children().get(columnaActual)).html(total)
          } // end of for loop
        }, // end of fnFootCallback function
        retrieve: true, // this hides the errors
        pageLength: 10,
        lengthMenu: [10,25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 50000, 100000, 500000, 1000000]
      
      })
      addTableButtons()
    }, 10000)
  })*/

  /*$(function () {
    var table = $('#tablez').DataTable()
    addTableButtons()
  })*/

  function addTableButtons () {
    var table = $('#tablez').DataTable()

    new $.fn.dataTable.Buttons(table, {
      buttons: [
        {
          extend: 'copy',
          text: 'Copy <i class="fa fa-files-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        },
        {
          extend: 'excel',
          text: 'Excel <i class="fa fa-file-excel-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        },
        //{ extend: 'csv', text: 'CSV <i class="fa fa-file-text-o"></i>', className: 'dtButton',exportOptions: {columns: [0, 1, 2, 3, 4, 5, 6, 7 ] } },
        {
          extend: 'pdf',
          text: 'PDF <i class="fa fa-file-pdf-o"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        },
        {
          extend: 'print',
          text: 'Print <i class="fa fa-print"></i>',
          className: 'dtButton',
          exportOptions: { columns: [0, 1, 2, 3, 4, 5, 6, 7] }
        }
      ]
    })

    table.buttons(0, null).containers().appendTo('#filterTopCriteria')
  }
  let systemSetupBranchIDStoreName = localStorage.getItem(
    'systemSetupBranchIDStoreName'
  )
  systemSetupBranchIDStoreName = JSON.parse(systemSetupBranchIDStoreName)


  
  var barcodeArr = []
  var stockQuantityBarcodeArr = []
  const [serviceList, setServiceList] = useState([
    {
      barcode: '',
      stockQuantityBarcode: ''
    }
  ])

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      {
        barcode: '',
        stockQuantityBarcode: ''
      }
    ])
  }
  const handleServiceRemove = index => {
    const list = [...serviceList]
    list.splice(index, 1)
    setServiceList(list)

    list.map(val => {
      barcodeArr.push(val.barcode)
      stockQuantityBarcodeArr.push(val.stockQuantityBarcode)
    })
  }
  const handleChange = (e, index) => {
    var { name, value } = e.target
    const list = [...serviceList]
    list[index][name] = value
    setServiceList(list)

    list.map(val => {
      barcodeArr.push(val.barcode)
      stockQuantityBarcodeArr.push(val.stockQuantityBarcode)
    })    

    setBarcode(JSON.stringify(barcodeArr))
    setStockQuantityBarcode(JSON.stringify(stockQuantityBarcodeArr))
    //alert('Barcode Value ='+barcode);
    
  }

  const [barcode, setBarcode] = useState([{}])
  const [stockQuantityBarcode, setStockQuantityBarcode] = useState([{}])


  return (
    <header>
      <>
        <title>
          KANCH INNOVATIVE TECHNOLOGIES - View Business Stock -{' '}
          {systemSetupBranchIDStoreName}{' '}
        </title>
        <>
          <Headers />
        </>
        {/*<!-- Preloader -->*/}
        <div hidden={handleSubmitBtn ===''}>
        <div id='overlayer'></div>
        <div class="loader d-flex justify-content-center">
      <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
            <span class='visually-hidden'>Loading...</span>
          </div>
 
        </div>
        </div>

        <div hidden={handleSubmitBtnDelete ===''}>
        <div class="loader d-flex justify-content-center">          
          <div class="spinner-border text-danger" role="status" style={{width: '10rem', height: '10rem'}}>
          <span class=''>Loading...</span>
</div>
        </div>
        </div>
        <section class='fixed-sn white-skin'>
          <div class=' d-flex justify-content-center align-items-center'>
            <div class='container  col-12'>
              <div class='card mt-5'>
                <div class='card-body' style={{ overflowX: 'auto' }}>
                  {/*<div class="text-center">  <p > <a href="Home" class="blue-text"> Home</a> </p> </div> */}

                  {/* Data Table View Starts Here*/}
                  <div class=''>
                    {/*<div class="row">*/}
                    <br />
                    <h6 class='form-header white-text ocean blue-gradient'>
                      VIEW STOCK{' '}
                      <span style={{ float: 'right' }}>
                        {secondUserPosition === 'Business Owner' ||
                        secondUserPosition === 'Manager' ? (
                          <>
                            <a onClick={handleRegisterStock}>
                              {' '}
                              <i class='w-fa fa fa-plus'></i> Add Stock
                            </a>{' '}
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                    </h6>

                                        {/*<!-- Modal: modalManagerLogin  
                                        <div
                      class='modal fade col-lg-12'
                      id='modalUpdateBarcode'
                      tabindex='-1'
                      role='dialog'
                      aria-labelledby='myModalLabel'
                      aria-hidden='true'
                    >
                    -->*/}
 <MDBModal tabIndex='-1' id='modalUpdateBarcode'>
        <MDBModalDialog size='xl'>
          <MDBModalContent>
            <MDBModalHeader class='modal-header ocean blue-gradient white-text '>
              <MDBModalTitle><h4 class=''>Edit Stock Barcode : </h4></MDBModalTitle>
              <button
                              type='button'
                              class='close waves-effect waves-light white-text'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
            </MDBModalHeader>
            <MDBModalBody>
                          

                  {/*<!--Grid row--> */}
                  {serviceList.map((singleServiceList, index) => {
                    return (
                      <>
                        <div
                          class='row'
                          key={index}
                        >
                          <div class='col-md-1 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                {index + 1} .<span class='red-text'></span>
                              </label>
                              <br /> <br />
                            </div>
                          </div>

                          <div class='col-md-7 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span>
                                  Barcode
                                </span>
                                <span class='red-text'></span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.barcode}
                                onChange={e => handleChange(e, index)}
                                id='barcode'
                                name='barcode'
                              />
                            </div>
                          </div>

                          <div class='col-md-2 mb-1'>
                            <div class='md-form'>
                              <label for='registerFullName'>
                                <span>
                                  Qty
                                </span>
                                <span class='red-text'></span>
                              </label>
                              <br /> <br />
                              <input
                                type='text'
                                class='form-control'
                                placeholder=''
                                value={singleServiceList.stockQuantityBarcode}
                                onChange={e => handleChange(e, index)}
                                id='stockQuantityBarcode'
                                name='stockQuantityBarcode'
                              />
                            </div>
                          </div>

                          {serviceList.length > 1 && (
                            <div class='col-md-2 mb-1'>
                              <div class='md-form'>
                                <br /> <br /> <br />
                                <input
                                  type='button'
                                  class='form-control btn white-text red accent-2'
                                  value='X'
                                  onClick={handleServiceRemove}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          class='row'
                        >
                          {serviceList.length - 1 === index && (
                            <div class='col-md-12'>
                              <div class='md-form'>
                                <button
                                  class='btn white-text blue accent-2 col-md-12'
                                  onClick={() => handleServiceAdd(index)}
                                >
                                  + Add Another Item Barcode
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  })}
                  {/*<!--\Grid row--> */}

                  <div class='row mt-5'>
                  <div class='col-md-12'>
                              <div class='md-form'>
                                <button
                                  class='btn white-text blue accent-2 col-md-12'
                                  onClick={handleUpdateStockBarcode}
                                  disabled={handleSubmitBtnUpdateBarcode === 'Loading'}
                                >
                                  <div class="spinner-border" role="status" hidden = {handleSubmitBtnUpdateBarcode === ''}>
</div>
                            <span class='fa fa-update'> Update</span>
                                </button>
                              </div>
                            </div>
                  </div>

                  </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
                    {/* <!-- Modal: modalUpdateBarcode .-->*/}

                    {/*<!-- Modal: modalUpdateStockItem  -->*/}
                    <MDBModal  id='modalManagerLogin' tabIndex='-1'>
        <MDBModalDialog size='xl'>
                        {/*<!-- Content -->*/}
                        <div class='modal-content'>
                          {/* <!-- Header -->*/}
                          <div class='modal-header ocean blue-gradient white-text '>
                            <h4 class=''>Edit Stock :</h4>
                            <button
                              type='button'
                              class='close waves-effect waves-light white-text'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
                          </div>
                          {/*<!-- Body -->*/}
                          <div class='modal-body mb-0 '>
                            {DBtableViewOneStock.map(valOneStockItem => {
                              return (
                                <>
                                  <table class='' style={{ width: '100%' }}>
                                    <tr>
                                      <th>OLD</th> <th>NEW</th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Unit Price{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              name='lastName'
                                              id='lastName'
                                              autoComplete='off'
                                              value={
                                                valOneStockItem.product_unit_price
                                              }
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>{' '}
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Unit Price{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              onChange={e =>
                                                setUnitPrice(e.target.value)
                                              }
                                              value={unitPrice}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Stock Quantity{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              name='firstName'
                                              id='firstName'
                                              autoComplete='off'
                                              value={
                                                valOneStockItem.stock_quantity
                                              }
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>{' '}
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            <label>
                                              Stock Quantity{' '}
                                              <i class='red-text'></i>{' '}
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              onChange={e =>
                                                setStockQuantity(e.target.value)
                                              }
                                              value={stockQuantity}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            {' '}
                                            <label for='registerFullName'>
                                              Stock Unit Cost{' '}
                                              <i class='red-text'></i>
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              name='phone'
                                              id='phone'
                                              autoComplete='off'
                                              value={
                                                valOneStockItem.product_unit_cost
                                              }
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </td>{' '}
                                      <td>
                                        <div class='col-lg-12 col-md-6 mb-1'>
                                          <div class='md-form'>
                                            {' '}
                                            <label for='registerFullName'>
                                              Stock Unit Cost{' '}
                                              <i class='red-text'></i>
                                            </label>
                                            <br /> <br />
                                            <input
                                              type='text'
                                              class='form-control'
                                              autoComplete='off'
                                              onChange={e =>
                                                setStockUnitCost(e.target.value)
                                              }
                                              value={stockUnitCost}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {/*End Of Row 3*/}
                                    <tr>
                                      {' '}
                                      <td colspan='2'>
                                        <button
                                          class='btn btn-primary'
                                          onClick={handleSubmitUpdate}
                                          disabled={
                                            stockQuantity === '' &&
                                            stockUnitCost === '' &&
                                            unitPrice === ''
                                           || handleSubmitBtnUpdate === 'Loading'}
                                        >
                                          <div class="spinner-border" role="status" hidden = {handleSubmitBtnUpdate === ''}>
</div>
                                          Update
                                        </button>
                                      </td>
                                    </tr>
                                  </table>
                                </>
                              )
                            })}
                          </div>
                          {/*Modal Body Ends Here*/}
                        </div>
                        </MDBModalDialog>
      </MDBModal>
                    {/* <!-- Modal: modalManager .-->*/}
                    <center>
                      <div id='filterTopCriteria'> </div>
                    </center>

                    <table
                      id='tablez'
                      class='table table-striped table-bordered table-hover table-sm nowrap'
                      width='100%'
                    >
                      <thead class='form-header text-uppercase ocean blue-gradient'>
                        {/*<tr>
<td colspan="6"><h1 class="card-header white-text">REGISTERED BUSINESSES</h1></td>
</tr>*/}
                        <tr>
                          {/*
SELECT `stockID`, `system_setupID`, `system_setup_branchID`, `stock_batchID`, `productID`, 
`stock_quantity`, `stock_cost`, `suppliersID`, `product_unit_price`, `stockArrivalDate`, 
`stock_date`, `stock_time`, `stock_entered_by`, `stock_IP` FROM `stock` WHERE 1
*/}
                          <th>S/N</th>
                          {/*<th>Company</th>*/}
                          <th>Branch</th>
                          <th>product</th>
                          <th>Ordered Qty</th>
                          <th>Available Qty</th>
                          <th>Cost</th>
                          <th>Price</th>
                          <th>Date(Arrival)</th>
                          <th>Barcode</th>
                          {secondUserPosition === 'Manager' ||
                          secondUserPosition === 'Business Owner' ? (
                            <>
                              <th>UPDATE</th>
                              <th>DELETE</th>
                            </>
                          ) : (
                            <></>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
    <td>1</td> <td>product</td> <td>Quantity</td> <td>cost</td> <td>price</td>
    <td>date</td> <td>supplier</td> <td>update</td> <td>delete</td>
  </tr>*/}
                        {DBtableViewStock.map((val, index) => {
                          return (
                            <tr key={val.stockID}>
                              {/*system_setupID`, `system_setup_branchID`, `Last_name`, `first_name`, `email`, `phone`, 
`image`, `password`, `thumb_print`, `position`, `role`,   */}
                              <td>{index + 1}</td>
                              {/*<td>{val.organisation}</td>*/}
                              <td>{val.branch_name}</td>
                              <td>{val.product}</td>
                              <td>
                              <NumericFormat value={val.initial_stock_quantity} displayType={'text'} thousandSeparator={true} prefix={''} />
                              </td>
                              <td>
                              <NumericFormat value={val.stock_quantity} displayType={'text'} thousandSeparator={true} prefix={''} />
                              </td>
                              <td>
                              <NumericFormat value={val.stock_cost} displayType={'text'} thousandSeparator={true} prefix={''} />
                              </td>
                              <td>
                              <NumericFormat value={val.product_unit_price} displayType={'text'} thousandSeparator={true} prefix={''} />
                              </td>
                              <td>{val.stockArrivalDate}</td>
                              <td>
                                    <span
                                      onClick={() => {
                                        UpdateBarcode(val.stockID)
                                      }}
                                      type='button'
                                      class='fa fa-barcode black-text'
                                      title='Click This Button To Update Barcode For This Record'
                                      data-toggle='modal'
                                      data-target='#modalUpdateBarcode'
                                    ></span>
                                </td>
                              {/*<td>{val.supplier_name}</td>*/}
                              {secondUserPosition === 'Manager' ||
                              secondUserPosition === 'Business Owner' ? (
                                <>
                                  <td>
                                    {' '}
                                    <span
                                      onClick={() => {
                                        UpdateRecord(val.stockID)
                                      }}
                                      type='button'
                                      class='fa fa-edit blue-text'
                                      title='Update This Record'
                                      data-toggle='modal'
                                      data-target='#modalManagerLogin'
                                    ></span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        deleteRecord(val.stockID)
                                      }}
                                      type='button'
                                      class='fa fa-trash red-text'
                                      title='Delete This Record From The Database'
                                      disabled={handleSubmitBtnDelete === 'Loading'}
                                    >
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                      {/*} <tfoot class='form-header text-center'>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>TOTALS</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </tfoot>
                      */}
                    </table>
                  </div>
                  {/* Data Table View Ends Here*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </header>
  )
}

export default ViewStock
